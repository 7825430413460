import styled from "styled-components";

export const Wrap = styled.div<{ $show?: boolean }>`
  fill: transparent;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  width: 100%;
  height: 100%;
  position: absolute;
  svg {
    width: 100%;
    height: 100%;
  }
`;
