import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { ButtonEnum } from "../../beans";
import PaddingManager from "../../components/PaddingManager";
import { Wrap } from "./styles";
import Button from "../../components/Button";
import { routes } from "../../constants/routes";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Wrap>
        <PaddingManager>
          <div className="title">404</div>
          <div className="heading-md">Ooops, Page Not Found</div>
          <div>
            <p className="title-lg">
              The page you are looking for might have been removed, had its name
              changed, or is temporarily unavailable.
            </p>
          </div>
          <div className="action">
            <Button
              type={ButtonEnum.primary}
              onClick={() => navigate(routes.home)}
            >
              Go back
            </Button>
          </div>
        </PaddingManager>
      </Wrap>
    </>
  );
};

export default NotFound;
