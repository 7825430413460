import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;

  .sticky-summary {
    position: sticky;
    top: 76px;
    width: 100%;
  }
  .summary-detail {
    padding: 8px 0;
    .row {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .price {
        color: var(--gray130);
      }
    }

    .vat-info {
      margin-top: 8px;
      text-align: right;
      color: var(--gray90);
    }
  }
  .button-container {
    margin-top: 24px;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
