import * as yup from "yup";
import { phoneRegExp } from "./regex";

// RESGISTRATION SCHEMA
export const RegistrationSchema = yup.object({
  first_name: yup.string().required().label("First name"),
  last_name: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  phone: yup
    .string()
    .required()
    .matches(
      phoneRegExp,
      "Please include a valid country code with your phone number (e.g., +34 for Spain)"
    ),
  password1: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum"),
  password2: yup
    .string()
    .required()
    .oneOf([yup.ref("password1")], "Passwords must match"),
});

// LOGIN SCHEMA
export const loginSchema = yup.object({
  email: yup.string().trim().email().required().label("Email"),
  password: yup.string().required("No password provided."),
});

// PASSWORD SCHEMA
export const passwordSchema = yup.object({
  email: yup.string().trim().email().required().label("Email"),
});

// SHIPPING SCHEMA
export const shippingSchema = yup.object({
  first_name: yup.string().required().label("First name"),
  last_name: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  phone: yup
    .string()
    .matches(
      phoneRegExp,
      "Please include a valid country code with your phone number (e.g., +34 for Spain)"
    ),
  company_name: yup.string(),
  vat_number: yup.string(),
  address_line_1: yup.string().required().label("Address"),
  address_line_2: yup.string().label("Address"),
  postcode: yup.string().required().label("Postcode"),
  province: yup.string().required().label("Province"),
  city: yup.string().required().label("City"),
  country: yup.string().required().label("Country"),
  same_as_billing: yup.boolean().required(),
});

// BILLING SCHEMA
export const billingSchema = yup.object({
  first_name: yup.string().required().label("First name"),
  last_name: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  phone: yup
    .string()
    .matches(
      phoneRegExp,
      "Please include a valid country code with your phone number (e.g., +34 for Spain)"
    ),
  company_name: yup.string(),
  vat_number: yup.string(),
  address_line_1: yup.string().required().label("Address"),
  address_line_2: yup.string().label("Address"),
  postcode: yup.string().required().label("Postcode"),
  province: yup.string().required().label("Province"),
  city: yup.string().required().label("City"),
  country: yup.string().required().label("Country"),
});

// SIZE SELECTOR SCHEMA
export const sizeSelectorSchema = yup
  .object({
    x_small: yup
      .number()
      .positive()
      .integer()
      .required()
      .min(0)
      .transform((value) => (isNaN(value) ? 0 : value)),
    small: yup
      .number()
      .positive()
      .integer()
      .required()
      .min(0)
      .transform((value) => (isNaN(value) ? 0 : value)),
    medium: yup
      .number()
      .positive()
      .integer()
      .required()
      .min(0)
      .transform((value) => (isNaN(value) ? 0 : value)),
    large: yup
      .number()
      .positive()
      .integer()
      .required()
      .min(0)
      .transform((value) => (isNaN(value) ? 0 : value)),
    x_large: yup
      .number()
      .positive()
      .integer()
      .required()
      .min(0)
      .transform((value) => (isNaN(value) ? 0 : value)),
  })
  .required();

// PASSWORD SCHEMA

// CONFIRM PWD SCHEMA
export const ConfirmPasswordSchema = yup.object({
  new_password1: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum"),
  new_password2: yup
    .string()
    .oneOf([yup.ref("new_password1")], "Passwords must match"),
});

//////////// TYPES ////////////////

export type sizesSelectorForm = {
  x_small: number;
  small: number;
  medium: number;
  large: number;
  x_large: number;
};

export type registrationForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password1: string;
  password2: string;
};

export type loginForm = {
  email: string;
  password: string;
};
