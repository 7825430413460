export const breakpoints = {
  mobile: 500,
  /** mobile: from 0 to 500 */
  tablet: 768,
  /** tablet: from 600px to 904px */
  desktop: 1024,
  /** desktop: from 905px to 1280*/
  desktopWide: 1366,
  /** desktop wide: from 1240 to 1439 */
  desktopLarge: 1440,
  /** desktop large: from 1440 */
};
