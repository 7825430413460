import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.nav<{ $sticky?: boolean }>`
  height: calc(100vh - 104px);
  .tab-menu {
    position: ${(props) => (props.$sticky ? "sticky" : "static")};
    top: 0;
    z-index: 99;
    display: flex;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 52px;
    padding: 16px 40px 0px 40px;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    background: var(--white);
    border-bottom: 1px solid var(--purple30);
  }
  .tab-panel {
    max-height: 100%;
    overflow-y: scroll;
    background: var(--white);
    padding: 24px 0;
    padding-bottom: 90px;
    .padding-manager {
      padding-bottom: 24px;
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    .tab-menu {
      padding: 16px 16px 0px 16px;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .tab-panel {
      height: calc(40vh - var(--headerHeight));
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .tab-panel {
      height: calc(45vh - var(--headerHeight));
    }
  }
`;
