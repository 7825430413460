import styled from "styled-components";

export const Wrap = styled.div`
  background: var(--gray20);
  min-height: calc(100vh - var(--headerHeight));
  height: 100%;

  padding-top: 24px;
  padding-bottom: 24px;
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .empty-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: calc(100vh - var(--headerHeight) - 100px);
    svg {
      path {
        fill: var(--purple100);
      }
    }
  }
`;
