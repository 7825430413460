import { TabEnum } from "../beans";

export const tabs = [
  {
    id: TabEnum.fabric,
    label: "Fabric",
    url: "/fabbric",
    selected: true,
  },

  {
    id: TabEnum.print,
    label: "Print",
    url: "/print",
  },
  // {
  //   id: TabEnum.label,
  //   label: "Label",
  //   url: "/label",
  // },

  {
    id: TabEnum.summary,
    label: "Summary",
    url: "/summary",
  },
];
