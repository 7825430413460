export const initialProduction_100 = {
  x_small: 10,
  small: 20,
  medium: 30,
  large: 25,
  x_large: 15,
  sample: false,
};

export const initialProduction_200 = {
  x_small: 20,
  small: 40,
  medium: 60,
  large: 50,
  x_large: 31,
  sample: false,
};

export const initialProduction_300 = {
  x_small: 30,
  small: 60,
  medium: 90,
  large: 75,
  x_large: 46,
  sample: false,
};
