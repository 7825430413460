import styled from "styled-components";
import { loading } from "../../design-system/animations";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .title {
    width: 171px;
    height: 20px;
  }
  .blocks-container {
    box-sizing: border-box;
    display: flex;
    height: 72px;
    align-items: flex-start;
    border-radius: 4px;
    gap: 16px;
    flex: 1 0 0;
    .block {
      width: 33%;
      border-radius: 4px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--gray30);
      background: var(--white);
      gap: 16px;

      .title {
        width: 100%;
        height: 20px;
      }
      .description {
        width: 80%;
        height: 20px;
      }
    }
  }
  .animate {
    color: transparent;
    background: linear-gradient(
      100deg,
      var(--gray20) 30%,
      var(--gray30) 50%,
      var(--gray20) 70%
    );
    background-size: 400%;
    background-position: 100% 50%;
    animation: ${loading} 1.8s ease-in-out infinite;
  }
`;
