import { useDispatch, useSelector } from "react-redux";
import { ArtworkDetail as ArtworkDetailEnum, ButtonEnum } from "../../beans";
import { AppDispatch, RootState } from "../../store";
import ArtworkDetail from "../ArtworkDetail";
import Button from "../Button";
import Divider from "../Divider";
import SubsectionTitle from "../SubsectionTitle";
import { Wrap } from "./styles";
import { translatePrintLocationById } from "../../utils/translate";
import { patchUserDesignThunk } from "../../store/UserDesign";
import { useState } from "react";
import { selectTab } from "../../store/UI";
import LoadingOptions from "../LoadingOptions";

const Summary = () => {
  const dispatch = useDispatch<AppDispatch>();

  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const printLocations = useSelector(
    (state: RootState) => state.printLocations.data
  );
  const selectedFabric = useSelector(
    (state: RootState) => state.fabrics.selectedFabric
  );
  const selectedColorFabric = useSelector(
    (state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
  );
  const artworks = useSelector(
    (state: RootState) => state.userDesign.data?.print_set
  );

  const [name, setName] = useState(userDesign?.name);
  const [showEditName, setShowEditName] = useState(false);

  const editProductName = () => {
    dispatch(
      patchUserDesignThunk({
        designId: userDesign?.id,
        payload: { name: name },
      })
    );
    setShowEditName(false);
  };

  const handleChangeName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  if (!userDesign || !selectedFabric || !selectedColorFabric)
    return <LoadingOptions />;
  return (
    <Wrap>
      <SubsectionTitle title="Product name">
        <Button type={ButtonEnum.link} onClick={() => setShowEditName(true)}>
          Edit
        </Button>
      </SubsectionTitle>
      {!showEditName ? (
        <div className="body-sm">{userDesign.name}</div>
      ) : (
        <div className="edit-block">
          <form onSubmit={editProductName}>
            <div className="input-row">
              <input
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={handleChangeName}
                placeholder={userDesign.name}
              />
            </div>
          </form>
          <Button type={ButtonEnum.primary} onClick={() => editProductName()}>
            Save
          </Button>
          <Button
            type={ButtonEnum.secondary}
            onClick={() => setShowEditName(false)}
          >
            Cancel
          </Button>
        </div>
      )}
      <Divider />
      <SubsectionTitle title="Fabric">
        <Button
          type={ButtonEnum.link}
          id="fabric"
          onClick={() => dispatch(selectTab("fabric"))}
        >
          Edit
        </Button>
      </SubsectionTitle>
      <div className="body-sm">
        {selectedFabric.name} {selectedColorFabric.color.name}
      </div>
      {artworks ? (
        <>
          {artworks.map((artwork: ArtworkDetailEnum, k: number) =>
            artwork.location ? (
              <div className="artwork-details-summary" key={k}>
                <Divider />
                <SubsectionTitle
                  title={translatePrintLocationById(
                    artwork.location,
                    printLocations
                  )}
                />
                <ArtworkDetail artwork={artwork} />
              </div>
            ) : null
          )}
        </>
      ) : null}
    </Wrap>
  );
};

export default Summary;
