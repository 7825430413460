import { ButtonEnum, Country, shippipngForm } from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import SubsectionTitle from "../SubsectionTitle";
import {
  getShippingFromOrderThunk,
  setShippingRequest,
  updateShippingRequest,
} from "../../store/Shipping";
import FormContainer from "../FormContainer";
import { getCountryList } from "../../api/countries";
import BillingForm from "../BillingForm";
import { useNavigate } from "react-router";
import { goToOrderCheckout } from "../../constants/routes";
import { userRequest } from "../../store/User";
import { shippingSchema } from "../../utils/form";

type Props = {
  designId: string | undefined;
  orderId: string | undefined;
  productionId: string | undefined;
};

const ShippingForm = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const shippingFormRef = useRef<HTMLFormElement>();

  const { designId, orderId, productionId } = props;
  const user = useSelector((state: RootState) => state.user.data);
  const shippingState = useSelector((state: RootState) => state.shipping);
  const shippingError = shippingState.error;
  const shipping = shippingState.data;

  const [countries, setCountries] = useState([]);
  const [showBilling, setShowBilling] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await getCountryList();

      setCountries(response.data);
    };
    fetchCountries();
    orderId && dispatch(getShippingFromOrderThunk(orderId));
    dispatch(userRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      setValue("last_name", user.last_name);
      setValue("first_name", user.first_name);
      setValue("email", user.email);
      setValue("phone", user.phone);
    }
    if (shipping?.shipping_id) {
      setValue("last_name", shipping.last_name);
      setValue("first_name", shipping.first_name);
      setValue("email", shipping.email);
      setValue("phone", shipping.phone);

      setValue("company_name", shipping.company_name);
      setValue("address_line_1", shipping.address_line_1);
      setValue("address_line_2", shipping.address_line_2);

      setValue("postcode", shipping.postcode);
      setValue("province", shipping.province);
      setValue("city", shipping.city);
      setValue("country", shipping.country);
      setValue("same_as_billing", shipping.same_as_billing);
    }
  }, [user, shipping]); // eslint-disable-line react-hooks/exhaustive-deps

  // HOOK-FORM

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<shippipngForm>({
    // @ts-ignore
    resolver: yupResolver(shippingSchema),
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = () => {
    const body = {
      orderId: orderId,
      shippingId: shipping?.shipping_id,
      payload: getValues(),
    };

    shipping?.shipping_id
      ? dispatch(updateShippingRequest(body))
      : dispatch(setShippingRequest(body));

    if (shipping && shipping.shipping_id) {
      if (
        designId &&
        orderId &&
        productionId &&
        !shippingError &&
        !shippingState.loading
      )
        navigate(goToOrderCheckout(designId, orderId, productionId));
    }
  };

  return (
    <Wrap>
      <div className="shipping-form">
        <SubsectionTitle title={"Shipping address"} />
        <FormContainer>
          {/* @ts-ignore */}
          <form onSubmit={handleSubmit(handleOnSubmit)} ref={shippingFormRef}>
            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">First name</label>
                <input
                  key="first_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("first_name")}
                />
                {errors["first_name"] && (
                  <small className="error-field">
                    {errors["first_name"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Last name</label>
                <input
                  key="last_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("last_name")}
                />
                {errors["last_name"] && (
                  <small className="error-field">
                    {errors["last_name"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Email</label>
                <input
                  key="email"
                  className={`size-selector-input`}
                  type="text"
                  {...register("email")}
                />
                {errors["email"] && (
                  <small className="error-field">
                    {errors["email"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Phone number</label>
                <input
                  key="phone"
                  className={`size-selector-input`}
                  type="text"
                  placeholder="+34123456789"
                  {...register("phone")}
                />
                {errors["phone"] && (
                  <small className="error-field">
                    {errors["phone"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Company name</label>
                <input
                  key="company_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("company_name")}
                />
                {errors["company_name"] && (
                  <small className="error-field">
                    {errors["company_name"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Vat number</label>
                <input
                  key="vat_number"
                  className={`size-selector-input`}
                  type="text"
                  {...register("vat_number")}
                />
                {errors["vat_number"] && (
                  <small className="error-field">
                    {errors["vat_number"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row full">
                <label className="label-sm">Address line 1</label>
                <input
                  key="address_line_1"
                  className={`size-selector-input`}
                  type="text"
                  {...register("address_line_1")}
                />
                {errors["address_line_1"] && (
                  <small className="error-field">
                    {errors["address_line_1"]?.message}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="input-row full">
                <label className="label-sm">Address line 2</label>
                <input
                  key="address_line_2"
                  className={`size-selector-input`}
                  type="text"
                  {...register("address_line_2")}
                />
                {errors["address_line_2"] && (
                  <small className="error-field">
                    {errors["address_line_2"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Postcode</label>
                <input
                  key="postcode"
                  className={`size-selector-input`}
                  type="text"
                  {...register("postcode")}
                />
                {errors["postcode"] && (
                  <small className="error-field">
                    {errors["postcode"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Province</label>
                <input
                  key="province"
                  className={`size-selector-input`}
                  type="text"
                  {...register("province")}
                />
                {errors["province"] && (
                  <small className="error-field">
                    {errors["province"]?.message}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">City</label>
                <input
                  key="city"
                  className={`size-selector-input`}
                  type="text"
                  {...register("city")}
                />
                {errors["city"] && (
                  <small className="error-field">
                    {errors["city"]?.message}
                  </small>
                )}
              </div>

              <div className="input-row">
                <label className="label-sm">Country</label>
                <select
                  key="country"
                  className={`size-selector-input`}
                  {...register("country")}
                >
                  <option key="" value="">
                    Select yout country
                  </option>
                  <option key="es" value="ES">
                    Spain
                  </option>
                  {countries.map((country: Country) => (
                    <option key={country.iso2} value={country.iso2}>
                      {country.country}
                    </option>
                  ))}
                </select>

                {errors["country"] && (
                  <small className="error-field">
                    {errors["country"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row full checkbox">
                <label className="label-sm">
                  Use shipping address as billing address
                </label>
                <input
                  key="same_as_billing"
                  className={`size-selector-input`}
                  type="checkbox"
                  {...register("same_as_billing")}
                  onChange={(e) => setShowBilling(e.target.checked)}
                />
                {errors["same_as_billing"] && (
                  <small className="error-field">
                    {errors["same_as_billing"]?.message}
                  </small>
                )}
              </div>
            </div>
          </form>
          {!showBilling && (
            <BillingForm
              orderId={orderId}
              designId={designId}
              productionId={productionId}
              shippingIsDirty={isDirty}
              shippingIsValid={isValid}
              shippingData={getValues()}
              shippingFormRef={shippingFormRef}
            />
          )}
          {showBilling && (
            <div className="form-row-button">
              <Button
                type={ButtonEnum.primary}
                // onClick={(e) => handleOnSubmit(e)}
                // disabled={!isDirty || !isValid}
                loading={shippingState.loading}
                onClick={handleSubmit(handleOnSubmit)}
              >
                Save and continue
              </Button>
            </div>
          )}
        </FormContainer>
      </div>
    </Wrap>
  );
};

export default ShippingForm;
