import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .logo {
    margin: auto;
    color: var(--purple100);
  }
  .description {
    text-align: center;
    margin-top: 24px;
  }

  .reset-pwd {
    align-items: flex-start;
    a {
      color: var(--gray130);
      text-decoration: underline;
    }
  }
`;
