import { Wrap } from "./styles";
import { priceFormat } from "../../utils/format";
import { ProductionRange as ProductionRangeBean } from "../../beans";

type Props = {
  productionTime?: string;
  productionRangeItem: ProductionRangeBean;
};

const ProductionRangeItem = (props: Props) => {
  const { productionTime, productionRangeItem } = props;

  return (
    <Wrap>
      {productionTime ? (
        <div className="production-detail-unit">
          <div className="label-sm">Production time</div>
          <div className="title-sm">{productionTime}</div>
        </div>
      ) : (
        <div className="production-detail-unit">
          <div className="label-sm">
            {productionRangeItem?.production_range}
          </div>
          <div className="title-sm">
            {priceFormat(productionRangeItem?.unit_total?.toString())} € / unit
          </div>
        </div>
      )}
    </Wrap>
  );
};

export default ProductionRangeItem;
