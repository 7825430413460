import { MouseEvent } from "react";
import { Wrap } from "./styles";

type Props = {
  url: string;
  selected?: boolean;
  disabled?: boolean;
  onClick: Function;
  children?: React.ReactNode;
  id: string;
};

const Tab = (props: Props) => {
  return (
    <Wrap
      disabled={props.disabled}
      className={props.selected ? "selected title-sm" : "title-sm"}
      aria-selected={props.selected}
      onClick={(e: MouseEvent<HTMLButtonElement>) => props.onClick(e)}
      role="tab"
      id={props.id}
      name={props.id}
    >
      {props.children}
    </Wrap>
  );
};

export default Tab;
