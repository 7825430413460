import { combineReducers } from "redux";
import Item from "./Item";
import Billing from "./Billing";
import Design from "./Design";
import Fabric from "./Fabric";
import PrintLocation from "./PrintLocation";
import PrintType from "./PrintType";
import ArtworkDetail from "./ArtworkDetail";
import ProductionRange from "./ProductionRange";
import Production from "./Production";
import Password from "./Password";
import ItemRenderList from "./ItemRenderList";
import Label from "./Label";
import Login from "./Login";
import User from "./User";
import UserDesign from "./UserDesign";
import Order from "./Order";
import Shipping from "./Shipping";
import Registration from "./Registration";
import UI from "./UI";

const reducers = combineReducers({
  artworkDetails: ArtworkDetail,
  billing: Billing,
  design: Design,
  fabrics: Fabric,
  items: Item,
  itemsRenderList: ItemRenderList,
  label: Label,
  login: Login,
  order: Order,
  printLocations: PrintLocation,
  printType: PrintType,
  production: Production,
  productionRange: ProductionRange,
  password: Password,
  shipping: Shipping,
  userDesign: UserDesign,
  registration: Registration,
  user: User,
  ui: UI,
});

export default reducers;
