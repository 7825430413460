import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .print-placement-options {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
`;
