import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
  box-sizing: border-box;
  height: calc(100vh - var(--headerHeight));
  position: relative;
  .design-container {
    position: absolute;
    overflow-y: hidden;
    height: 100%;
    display: flex;
    width: 100%;
    height: calc(100vh - var(--headerHeight));
    box-sizing: border-box;
    .design-preview {
      position: relative;
      box-sizing: border-box;
      width: calc(100% - var(--sidebarWidth));
      background-color: var(--gray20);
      height: calc(100vh - var(--headerHeight));
      min-height: calc(100vh - var(--headerHeight));
      max-height: 100%;

      .padding-manager {
        height: 100%;
      }
    }
    .design-options {
      height: 100%;
      background: var(--white);
      width: var(--sidebarWidth);
      box-sizing: border-box;
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    .design-container {
      .design-preview {
        width: calc(100% - var(--sidebarTabletWidth));
      }
      .design-options {
        width: var(--sidebarTabletWidth);
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .design-container {
      flex-direction: column;

      .design-preview {
        height: 50vh;
        min-height: 50vh;
        width: 100%;
      }
      .design-options {
        position: sticky;
        top: 50vh;
        width: 100%;
      }
    }
  }
`;
