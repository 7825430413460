import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  background: var(--gray20);
  min-height: calc(100vh - var(--headerHeight));
  height: 100%;
  .card-containers {
    padding: 24px 0;
    display: flex;
    gap: 48px;
    height: 100%;

    > div:first-of-type {
      min-width: 60%;
    }

    .card-containers-title {
      color: var(--gray130);
    }

    .order-type,
    .order-quantity {
      .order-type-options,
      .order-quantity-options {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
      }
      .order-type-options {
        .action-card {
          width: calc(50% - 16px);
        }
      }
      .order-quantity-options {
        .action-card {
          width: calc(33% - 16px);
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .card-containers {
      .order-quantity {
        .order-quantity-options {
          .action-card {
            width: calc(50% - 16px);
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .card-containers {
      flex-direction: column;

      .order-type,
      .order-quantity {
        .order-type-options,
        .order-quantity-options {
          .action-card {
            width: 100%;
          }
        }
      }
    }
  }
`;
