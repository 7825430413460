import { keyframes } from "styled-components";

export const loading = keyframes`
   from {
    background-position: 100% 50%;
  }
  to{
    background-position: 0 50%;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
