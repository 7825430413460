import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PrintLocation } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface PrintLocationState {
  data: PrintLocation[];
  error: null | any;
  selected: PrintLocation | null;
}

const initialState: PrintLocationState = {
  data: [],
  error: null,
  selected: null,
};

export const getPrintLocationThunk = createAsyncThunk(
  "printlocation/request",
  async () => {
    const response = await fetch(`${apiUrl}/designs/printlocation/`, {
      headers: { accept: "application/json" },
    });

    return await response.json();
  }
);

export const printLocationSlice = createSlice({
  name: "printLocation",
  initialState,
  reducers: {
    selectPrintLocation: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrintLocationThunk.pending, (state) => {
      state.data = [];
    });
    builder.addCase(getPrintLocationThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.selected = action.payload[0];
    });
    builder.addCase(getPrintLocationThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });
  },
});

export const { selectPrintLocation } = printLocationSlice.actions;

export default printLocationSlice.reducer;
