import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ButtonEnum, ModalEnum } from "../../beans";
import { PRODUCTION_TIME } from "../../constants/labels";
import {
  goToOrderShipping,
  isCheckoutRoute,
  isOrderCompletedRoute,
  isProductionRoute,
  isShippingRoute,
} from "../../constants/routes";
import { AppDispatch, RootState } from "../../store";
import { showModal } from "../../store/UI";
import { priceFormat } from "../../utils/format";
import Button from "../Button";
import Card from "../Card";
import Divider from "../Divider";
import LoadingOptions from "../LoadingOptions";
import { Wrap } from "./styles";

type Props = {
  summary: any;
  designId: string | undefined;
  productionId: string | undefined;
  orderId: string | undefined;
};

const ProductionSummary = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { designId, orderId, productionId, summary } = props;

  const shipping = useSelector((state: RootState) => state.shipping.data);
  const production = useSelector((state: RootState) => state.production);

  const isLogged = useSelector((state: RootState) => state.login.logged);

  const openRegistrationModal = () => {
    dispatch(showModal(ModalEnum.registration));
  };

  const goToNextStep = async () => {
    if (isLogged && designId && orderId && productionId) {
      navigate(goToOrderShipping(designId, orderId, productionId));
    } else {
      openRegistrationModal();
    }
  };

  return (
    <Wrap>
      <div className="sticky-summary">
        <Card title="Summary">
          {summary ? (
            <>
              <div className="summary-detail">
                <div className="row">
                  <div className="body-sm">Subtotal</div>
                  <div className="price title-sm">
                    {priceFormat(summary?.subtotal)} €
                  </div>
                </div>
                {isProductionRoute(window.location.pathname) ||
                  (isShippingRoute(window.location.pathname) && (
                    <>
                      <div className="vat-info label-sm">
                        All prices excl. VAT and shipping costs
                      </div>
                      <Divider />
                    </>
                  ))}
              </div>

              {(isCheckoutRoute(window.location.pathname) ||
                isOrderCompletedRoute(window.location.pathname)) && (
                <>
                  <div className="summary-detail">
                    <div className="row">
                      <div className="body-sm">Shipping cost</div>
                      <div className="title-sm">
                        {shipping?.shipping_id
                          ? `${priceFormat(summary?.shipping)} €`
                          : `${summary?.shipping} €`}
                      </div>
                    </div>
                  </div>
                  <div className="summary-detail">
                    <div className="row">
                      <div className="body-sm">VAT</div>
                      <div className="title-sm">
                        {`${priceFormat(summary?.vat)} €`}
                      </div>
                    </div>
                  </div>
                  <Divider />
                </>
              )}
              {!isProductionRoute(window.location.pathname) &&
                !isShippingRoute(window.location.pathname) && (
                  <div className="summary-detail">
                    <div className="row">
                      <div className="title-lg price">Total</div>
                      <div className="price title-lg">
                        {priceFormat(summary?.total)} €
                      </div>
                    </div>
                    {!isCheckoutRoute(window.location.pathname) &&
                      !isOrderCompletedRoute(window.location.pathname) && (
                        <div className="vat-info label-sm">
                          All prices excl. VAT and shipping costs
                        </div>
                      )}
                    <Divider />
                  </div>
                )}
              <div className="summary-detail">
                <div className="row">
                  <div className="body-sm">Production time</div>
                  <div className="price title-lg">{PRODUCTION_TIME}</div>
                </div>
              </div>
            </>
          ) : (
            <LoadingOptions />
          )}
        </Card>
        {summary && (
          <>
            <div className="button-container">
              {isProductionRoute(window.location.pathname) && (
                <Button
                  type={ButtonEnum.primary}
                  onClick={() => goToNextStep()}
                  disabled={production?.error}
                  loading={production.loading}
                >
                  Continue to checkout
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Wrap>
  );
};

export default ProductionSummary;
