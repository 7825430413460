import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware, { AnyAction } from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas";
import { thunk, ThunkDispatch } from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

export const makeStore = () => {
  const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  for (const saga of sagas) {
    sagaMiddleware.run(saga);
  }
  return store;
};

export type RootState = ReturnType<typeof reducers>;
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};
export type AppDispatch = AppStore["dispatch"];
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
