import { Wrap } from "./styles";
import { logo } from "../../constants/icons";
import Icon from "../Icon";

const Logo = () => {
  return (
    <Wrap>
      <Icon icon={logo} />
    </Wrap>
  );
};

export default Logo;
