import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  getProductionByIdRequest,
  setProductionRequest,
} from "../../store/Production";
import {
  getProductionRangeAvailability,
  getProductionRangeThunk,
  selectProductionRange,
} from "../../store/ProductionRange";
import { getOrderByIdRequest, getOrderSummaryRequest } from "../../store/Order";
import { getUserDesignThunk } from "../../store/UserDesign";

import { ActionCardEnum, ProductionRange } from "../../beans";
import SizeSelector from "../../components/SizeSelector";
import Card from "../../components/Card";
import ActionCard from "../../components/ActionCard";
import Divider from "../../components/Divider";
import ProductionDesigns from "../../components/ProductionDesigns";
import ProductionSummary from "../../components/ProductionSummary";
import PaddingManager from "../../components/PaddingManager";
import SampleSizeSelector from "../../components/SampleSizeSelector";
import SubsectionTitle from "../../components/SubsectionTitle";
import { Wrap } from "./styles";
import { routes } from "../../constants/routes";
import { PRODUCTION_TIME } from "../../constants/labels";
import LoadingOptions from "../../components/LoadingOptions";
import { priceFormat } from "../../utils/format";
import {
  initialProduction_100,
  initialProduction_200,
  initialProduction_300,
} from "../../constants/productions";
import { calculateTotalProductionItems } from "../../utils/orders";

const OrderProduction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { designId, orderId, productionId } = useParams();

  // GLOBAL STATE
  const selectedItem = useSelector(
    (state: RootState) => state.userDesign?.data?.item_fabric?.item
  );
  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const productionRange = useSelector(
    (state: RootState) => state.productionRange.data
  );

  const summary = useSelector((state: RootState) => state.order.summary?.costs);
  const production = useSelector((state: RootState) => state.production.data);
  const selectedProductionRange = useSelector(
    (state: RootState) => state.productionRange.selectedRange
  );

  // LOCAL STATE
  const [sample, setSample] = useState(false);

  // HOOKS
  useEffect(() => {
    // data inconsistency - go home
    if (!designId || !orderId || !productionId) navigate(routes.home);

    // retreive userDesign and Production range
    if (designId) {
      dispatch(getProductionRangeAvailability());
      dispatch(getUserDesignThunk(designId));
      dispatch(getProductionRangeThunk(Number(designId)));
    }
    // retreive production info
    if (productionId) {
      dispatch(
        getProductionByIdRequest({
          designId: designId,
          productionId: productionId,
        })
      );
    }
    // retreive order
    if (orderId) {
      dispatch(getOrderSummaryRequest(orderId));
      dispatch(getOrderByIdRequest(orderId));
    }
    if (productionRange.length > 0 && !selectedProductionRange) {
      dispatch(selectProductionRange(productionRange[1]));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set productionRange to second range if not setted selectedProductionRange
    if (productionRange.length > 0 && !selectedProductionRange) {
      dispatch(selectProductionRange(productionRange[1]));
    }
  }, [productionRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const total = calculateTotalProductionItems(production?.sizes);
    if (total && productionRange) {
      const range = productionRange.find(
        (range) => total >= range.minimum && total <= range.maximum
      );
      if (range) dispatch(selectProductionRange(range));
      else {
        dispatch(
          selectProductionRange(productionRange[productionRange.length - 1])
        );
      }
    }
  }, [production?.sizes]); // eslint-disable-line react-hooks/exhaustive-deps

  // HANDLERS
  const handleOrderSample = (sample: boolean) => {
    setSample(sample);
    if (!sample && selectedProductionRange) {
      handleOrderQuantity(selectedProductionRange);
    }
  };

  const handleOrderQuantity = (range: ProductionRange) => {
    dispatch(selectProductionRange(range));
    setDefaultProdutcQuantity(range.minimum);
  };

  const setDefaultProdutcQuantity = (minimum: number) => {
    let payload = {
      productionId: production?.id,
      orderId: orderId,
      body: initialProduction_100,
    };

    switch (minimum) {
      case 51:
      case 100:
        dispatch(setProductionRequest(payload));
        return;
      case 151:
      case 200:
        payload.body = initialProduction_200;
        dispatch(setProductionRequest(payload));
        return;
      case 301:
        payload.body = initialProduction_300;
        dispatch(setProductionRequest(payload));
        return;
      default:
        payload.body = initialProduction_300;
        dispatch(setProductionRequest(payload));
        return;
    }
  };

  return (
    <>
      <Header
        product
        productName={selectedItem?.name}
        productType={userDesign?.name}
        onClose={() => navigate(routes.home)}
      />{" "}
      <Wrap>
        <PaddingManager>
          <div className="card-containers">
            <Card title="Production" active>
              {userDesign && production && (
                <ProductionDesigns
                  edit
                  production={production}
                  userDesign={userDesign}
                />
              )}
              <Divider />
              {productionRange.length > 0 ? (
                <div className="order-type">
                  <SubsectionTitle title={"Order type"} />
                  <div className="order-type-options">
                    <ActionCard
                      onClick={() => handleOrderSample(false)}
                      key={"order-production"}
                      type={ActionCardEnum.expanded}
                      title="Order Production"
                      label={`Production time: ${PRODUCTION_TIME}`}
                      price={`from ${priceFormat(productionRange[productionRange.length - 1].unit_total)} €`}
                      selected={!sample}
                    />
                    <ActionCard
                      onClick={() => handleOrderSample(true)}
                      key={"sample-production"}
                      type={ActionCardEnum.expanded}
                      title="Order a sample"
                      label={`Production time: ${PRODUCTION_TIME}`}
                      price={`from ${priceFormat(productionRange[0].unit_total)} €`}
                      selected={sample}
                    />
                  </div>
                </div>
              ) : (
                <LoadingOptions />
              )}
              <Divider />
              {/* IF ORDER-TYPE */}
              {productionRange.length > 0 ? (
                <div className="order-quantity">
                  {!sample ? (
                    <>
                      <SubsectionTitle title={"Order quantity"} />

                      <div className="order-quantity-options">
                        {productionRange
                          .slice(1)
                          .map((range: ProductionRange, k: number) => (
                            <ActionCard
                              onClick={() => handleOrderQuantity(range)}
                              key={`order-quantity-${k}`}
                              type={ActionCardEnum.expanded}
                              title={`From ${range.minimum} units`}
                              label="XS to XL sizes"
                              price={`from ${priceFormat(range.unit_total)} €`}
                              selected={
                                JSON.stringify(selectedProductionRange) ===
                                JSON.stringify(range)
                              }
                            />
                          ))}
                      </div>
                      <Divider />
                      <SubsectionTitle title={"Size selection"} />
                      {production && orderId && (
                        <SizeSelector
                          orderId={orderId}
                          selectedProductionRange={selectedProductionRange}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <SubsectionTitle title={"Size selection"} />
                      {orderId && <SampleSizeSelector orderId={orderId} />}
                    </>
                  )}
                </div>
              ) : (
                <LoadingOptions />
              )}
            </Card>
            <ProductionSummary
              summary={summary}
              orderId={orderId}
              designId={designId}
              productionId={productionId}
            />
          </div>
        </PaddingManager>
      </Wrap>
    </>
  );
};

export default OrderProduction;
