import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { Login } from "../../beans";
import { authUrl } from "../../constants/endpoints";
import { deleteCookie, setCookie } from "../../utils/cookies";
import { routes } from "../../constants/routes";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";
export interface LoginState {
  token: Login;
  detail: string | null;
  logged: boolean | null;
  error: null | any;
  loading: boolean;
  loaded: boolean;
}

const initialState: LoginState = {
  token: { key: "" },
  detail: null,
  logged: null,
  error: null,
  loading: false,
  loaded: false,
};

// DEFINE SAGAS
function* loginSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();

    const response: any = yield call(fetch, `${authUrl}/login/`, {
      method: "POST",
      body: JSON.stringify(action.payload),
      headers: headers,
      credentials: "include",
    });
    const data = yield response.json();

    if (response.status === 200 || response.status === 201) {
      setCookie("token", data.key);
      yield put(loginSuccess(data));
      yield put(isLoggedRequest());
    } else {
      yield put(loginFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ERROR LOGIN", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(loginFailure({ serverError: [errorMessage] }));
    yield put(showErrorModal(getErrors(errorMessage)));
  }
}

function* logoutSaga(): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();

    const response: any = yield call(fetch, `${authUrl}/logout/`, {
      method: "POST",
      headers: headers,
      credentials: "include",
    });
    const data = yield response.json();

    if (response.ok) {
      deleteCookie("token");
      deleteCookie("sessionid");

      yield put(logoutSuccess(data));
      yield put(isLoggedRequest());
      window.location.assign(routes.home);
    } else {
      yield put(loginFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ERROR LOGOUT", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(logoutFailure({ serverError: [errorMessage] }));
    yield put(showErrorModal(errorMessage));
  }
}

function* isLoggedSaga(): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();

    const response: any = yield call(fetch, `${authUrl}/logged_in/`, {
      headers: headers,
      credentials: "include",
    });
    const data = yield response.json();

    if (response.ok) {
      yield put(isLoggedSuccess(data));
    } else {
      // delete session cookies
      deleteCookie("token");
      deleteCookie("sessionid");

      const errorMessage =
        "The server encountered an unexpected problem. Try again";

      yield put(isLoggedFailure(data));

      yield put(showErrorModal(errorMessage));
    }
  } catch (error: any) {
    // console.error("ERROR ISLOGGED", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(loginFailure({ serverError: [errorMessage] }));
  }
}
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest: (state, action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    loginFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    logoutRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    logoutSuccess: (state, action) => {
      state.detail = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    logoutFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    isLoggedRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    isLoggedSuccess: (state, action) => {
      state.logged = action.payload.logged;
      state.loading = false;
      state.loaded = true;
    },
    isLoggedFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  isLoggedRequest,
  isLoggedSuccess,
  isLoggedFailure,
} = loginSlice.actions;

export default loginSlice.reducer;

export function* sagas() {
  yield takeEvery(loginRequest.type, loginSaga);
  yield takeEvery(logoutRequest.type, logoutSaga);
  yield takeEvery(isLoggedRequest.type, isLoggedSaga);
}
