import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { authUrl } from "../../constants/endpoints";
import { getAuthHeader } from "../../api/auth";
import { showErrorModal } from "../UI";
import { getErrors } from "../../utils/errors";

export interface PasswordState {
  data: string | null;
  error: null | any;
  loading: boolean;
  loaded: boolean;
  email_sent: boolean;
  pwd_changed: boolean;
}

const initialState: PasswordState = {
  data: null,
  error: null,
  loading: false,
  loaded: false,
  email_sent: false,
  pwd_changed: false,
};

// DEFINE SAGAS

/// CHANNGE PASSWORD
function* changePasswordSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response: any = yield call(fetch, `${authUrl}/password/change/`, {
      method: "POST",
      body: JSON.stringify(action.payload), // { "new_password1": "string",  "new_password2": "string"}
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();
    if (response.ok) {
      yield put(changePasswordSuccess(data));
    } else {
      yield put(changePasswordFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    console.error("ERROR Password", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(resetPasswordFailure({ serverError: [errorMessage] }));
    yield put(showErrorModal(errorMessage));
  }
}

// RESET PASSWORD
function* resetPasswordSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response: any = yield call(fetch, `${authUrl}/password/reset/`, {
      method: "POST",
      body: JSON.stringify(action.payload), // {email: email@test.com}
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();
    if (response.ok) {
      yield put(resetPasswordSuccess(data));
    } else {
      yield put(resetPasswordFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    console.error("ERROR Password", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(resetPasswordFailure({ serverError: [errorMessage] }));
    yield put(showErrorModal(errorMessage));
  }
}

// CONFIRM PASSWORD
function* confirmPasswordSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response: any = yield call(
      fetch,
      `${authUrl}/password/reset/confirm/`,
      {
        method: "POST",
        body: JSON.stringify(action.payload), // {  "new_password1": "string",  "new_password2": "string",  "uid": "string",  "token": "string"}
        headers: headers,
        credentials: "include",
      }
    );

    const data = yield response.json();
    if (response.ok) {
      yield put(confirmPasswordSuccess(data));
    } else {
      yield put(confirmPasswordFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    console.error("ERROR Password", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(resetPasswordFailure({ serverError: [errorMessage] }));
    yield put(showErrorModal(errorMessage));
  }
}

export const PasswordSlice = createSlice({
  name: "Password",
  initialState,
  reducers: {
    changePasswordRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    changePasswordFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    resetPasswordRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.email_sent = true;
    },
    resetPasswordFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.email_sent = false;
      state.loading = false;
    },
    confirmPasswordRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    confirmPasswordSuccess: (state, action) => {
      state.data = action.payload.detail;
      state.loading = false;
      state.loaded = true;
      state.pwd_changed = true;
    },
    confirmPasswordFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
      state.pwd_changed = false;
    },
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  confirmPasswordRequest,
  confirmPasswordSuccess,
  confirmPasswordFailure,
} = PasswordSlice.actions;

export default PasswordSlice.reducer;

export function* sagas() {
  yield takeEvery(resetPasswordRequest.type, resetPasswordSaga);
  yield takeEvery(changePasswordRequest.type, changePasswordSaga);
  yield takeEvery(confirmPasswordRequest.type, confirmPasswordSaga);
}
