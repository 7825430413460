import { createSelector } from "reselect";
import { RootState } from "..";
import { UserDesign } from "../../beans";

const userDesignDomain = () => (state: RootState) => state.userDesign;

export const getUserDesign = () =>
  createSelector(userDesignDomain(), (substate): UserDesign | null => {
    return substate.data;
  });
