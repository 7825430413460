import { ArtworkDetail as ArtworkDetailType, ButtonEnum } from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { deleteArtworkDetailRequest } from "../../store/ArtworkDetail";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { translatePrintType } from "../../utils/translate";
type Props = {
  artwork?: ArtworkDetailType;
};
const ArtworkDetail = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const deleteArtwork = (artWork: ArtworkDetailType | undefined) => {
    if (artWork) dispatch(deleteArtworkDetailRequest(artWork.id));
  };
  return props.artwork ? (
    <Wrap>
      <div className="artwork-image">
        {props.artwork.original_resized && (
          <img src={props.artwork.original_resized} alt="" />
        )}
      </div>
      <div className="artwork-details">
        <div>
          {/* <div className="label-lg">Print quality: {props.artwork.quality}</div> */}
          {/* <div className="label-lg">
            Size: {props.artwork.width_cm} x {props.artwork.height_cm}
          </div> */}
          {/* <div className="label-lg">
            Colours: {props.artwork.number_of_colors}
          </div> */}
          {/* TODO printType response type alignment with backend */}
          <div className="label-lg">
            Print technique: {translatePrintType(props.artwork.type)}
          </div>
        </div>
        <div className="artwork-actions">
          <Button
            type={ButtonEnum.link}
            onClick={() => deleteArtwork(props.artwork)}
          >
            Delete
          </Button>
        </div>
      </div>
    </Wrap>
  ) : null;
};

export default ArtworkDetail;
