import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  background: var(--gray20);
  height: 100%;
  padding-top: 24px;
  .page-containers {
    padding: 24px 0;
    display: flex;
    gap: 48px;
    height: 100%;
    .page-content {
      min-width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .production-details {
        display: flex;
        gap: 24px;
        div {
          width: 100%;
        }
      }
      form {
        button {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.2px;
          cursor: pointer;
          display: flex;
          min-width: 96px;
          min-height: 40px;
          padding: 10px 16px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          border: 0;
          background: var(--purple100);
          color: var(--white);
          margin-top: 24px;
          &:hover {
            background: var(--purple110);
          }
          &:active {
            background: var(--purple120);
          }
          &:disabled {
            border: 1px solid var(--gray-30);
            background: var(--gray20);
            color: var(--gray90);
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .page-containers {
      flex-direction: column;
      .production-details {
        flex-direction: column;
        > div {
          width: 100%;
        }
      }
    }
  }
`;
