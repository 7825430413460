import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--white);

  .summary-header {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid var(--purple30);
    .title {
      padding: 16px 24px;
      width: 100%;
      color: var(--gray130);
    }
  }
  .edit-block {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;

    form {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  .artwork-details-summary {
    width: 100%;
  }
`;
