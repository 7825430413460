import { Wrap } from "./styles";
import { selectPrintLocation } from "../../store/PrintLocation";
import { RootState, AppDispatch } from "../../store";
import { useSelector, useDispatch } from "react-redux";

import { useEffect } from "react";
import {
  ActionCardEnum,
  AlertEnum,
  ArtworkDetail as ArtworkDetailBean,
  ButtonEnum,
  PrintLocation,
  PrintLocationEnum,
  PrintType,
  PrintTypeEnum,
} from "../../beans";
import {
  getPrintLocationIdFromName,
  translatePrintLocation,
} from "../../utils/translate";
import { info as infoIcon } from "../../constants/icons";

import SubsectionTitle from "../SubsectionTitle";
import ActionCard from "../ActionCard";
import { getPrintTypeThunk, selectPrintType } from "../../store/PrintType";
import FileDrop from "../FileDrop";
import ArtworkDetail from "../ArtworkDetail";
import LoadingOptions from "../LoadingOptions";
import { showInfoBox } from "../../store/UI";
import Button from "../Button";
import Icon from "../Icon";
import Alert from "../Alert";

const Print = () => {
  const dispatch = useDispatch<AppDispatch>();

  const printlocations = useSelector(
    (state: RootState) => state.printLocations.data
  );

  const printTypes = useSelector((state: RootState) => state.printType.data);

  const artworkDetail = useSelector(
    (state: RootState) => state.userDesign.data?.print_set
  );

  const selectedPrintLocation = useSelector(
    (state: RootState) => state.printLocations.selected
  );

  const selectedPrintType = useSelector(
    (state: RootState) => state.printType.selected
  );

  const userDesign = useSelector((state: RootState) => state.userDesign.data);

  useEffect(() => {
    dispatch(getPrintTypeThunk());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrintLocation = (printLocation: PrintLocation) => {
    dispatch(selectPrintLocation(printLocation));
  };

  const handlePrintType = (printType: PrintType) => {
    if (printType) dispatch(selectPrintType(printType));
  };

  const getCurrentArtwork = () => {
    if (!artworkDetail) return;

    return artworkDetail.find(
      (artwork: ArtworkDetailBean) =>
        artwork.location === selectedPrintLocation?.id
    );
  };

  const isDisabled = (printLocation: PrintLocation) => {
    let disabled = false;

    if (printLocation.placement === PrintLocationEnum.front_center) {
      disabled = !!userDesign?.print_set.find(
        (print) =>
          print.location ===
          getPrintLocationIdFromName(
            PrintLocationEnum.front_left,
            printlocations
          )
      );
    }

    if (printLocation.placement === PrintLocationEnum.front_left) {
      disabled = !!userDesign?.print_set.find(
        (print) =>
          print.location ===
          getPrintLocationIdFromName(
            PrintLocationEnum.front_center,
            printlocations
          )
      );
    }
    return disabled;
  };

  const isPrintTechniqueDisabled = (printType: PrintType) => {
    return !selectedPrintLocation?.types_available.includes(
      printType.type.toString()
    );
  };

  const renderPrintTypeAlert = () => {
    return selectedPrintType?.type !== PrintTypeEnum.dtf ? (
      <Alert type={AlertEnum.info}>
        <div>
          <strong>Important Notice</strong>:<br />
          For screen printing and embroidery, prints can only contain a{" "}
          <strong>maximum of 4 colours</strong>. <br />
          If your print exceeds this limit, it will not be produced. Please
          simplify your print to meet the colour requirements.
        </div>
      </Alert>
    ) : null;
  };

  return (
    <Wrap>
      {printlocations && printlocations?.length > 0 ? (
        <div className="print-options">
          <SubsectionTitle title={"Print placement"} />
          <div className="print-placement-options">
            {printlocations.map((printlocation: PrintLocation) => {
              return (
                <ActionCard
                  onClick={() => handlePrintLocation(printlocation)}
                  key={printlocation.id}
                  type={ActionCardEnum.simple}
                  title={translatePrintLocation(printlocation.placement)}
                  selected={selectedPrintLocation?.id === printlocation.id}
                  disabled={isDisabled(printlocation)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <LoadingOptions />
      )}
      {printTypes.length > 0 ? (
        <div className="print-options">
          <SubsectionTitle title={"Print technique"} />
          <div className="print-placement-options">
            {renderPrintTypeAlert()}
            {printTypes.map((printType: PrintType, k) => {
              return (
                <ActionCard
                  info
                  code={printType.type}
                  onClick={() => handlePrintType(printType)}
                  key={`print_type_${k}`}
                  type={ActionCardEnum.simple}
                  title={printType.label}
                  label={printType.description}
                  selected={selectedPrintType?.id === printType.id}
                  disabled={isPrintTechniqueDisabled(printType)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <LoadingOptions />
      )}
      <div className="print-options">
        <SubsectionTitle title={"Artwork"}>
          <Button
            type={ButtonEnum.icon}
            onClick={() =>
              dispatch(
                showInfoBox({
                  open: true,
                  title: "Artwork guidelines",
                  key: "artwork_guidelines",
                })
              )
            }
          >
            <Icon info icon={infoIcon}></Icon>
          </Button>
        </SubsectionTitle>
        {!getCurrentArtwork() ? (
          <FileDrop />
        ) : (
          <div className="print-options">
            <ArtworkDetail artwork={getCurrentArtwork()} />
          </div>
        )}
      </div>
    </Wrap>
  );
};

export default Print;
