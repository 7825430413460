import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .sizes-editor {
    form {
      .form-row {
        display: flex;
        gap: 8px;

        .size-row {
          display: flex;
          gap: 8px;
        }
        .input-row {
          .size-selector {
            height: 44px;
            width: 56px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .sizes-editor {
      form {
        .form-row {
          align-items: start;
          .size-row {
            flex-wrap: wrap;
            .input-row {
              width: 20%;
              input {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .sizes-editor {
      form {
        .form-row {
          .size-row {
            .input-row {
              width: calc(33% - 5px);
            }
          }
        }
      }
    }
  }
`;
