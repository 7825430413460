import PaddingManager from "../../components/PaddingManager";
import Header from "../../components/Header";
import { Wrap } from "./styles";
import Card from "../../components/Card";
import { useNavigate, useParams } from "react-router";
import ConfirmPasswordForm from "../../components/ConfirmPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Button from "../../components/Button";
import { ButtonEnum, ModalEnum } from "../../beans";
import { showModal } from "../../store/UI";
import { useEffect } from "react";
import { routes } from "../../constants/routes";

const PasswordConfirm = () => {
  const { uid, token } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const passwordRequestState = useSelector(
    (state: RootState) => state.password
  );
  const isLogged = useSelector((state: RootState) => state.login.logged);

  // HOOKS
  useEffect(() => {
    isLogged && navigate(routes.home);
  }, [isLogged, navigate]);

  return uid && token ? (
    <>
      <Header />
      <Wrap>
        <PaddingManager>
          <Card title="Reset your password">
            {!passwordRequestState.pwd_changed ? (
              <>
                <div className="body-sm">
                  Your new password must be different from previously used
                  passwords.
                </div>
                <div>
                  <ConfirmPasswordForm uid={uid} token={token} />
                </div>
              </>
            ) : (
              <>
                <p className="body-sm">{passwordRequestState.data}</p>
                <Button
                  type={ButtonEnum.primary}
                  onClick={() => dispatch(showModal(ModalEnum.login))}
                  loading={passwordRequestState.loading}
                >
                  Back to login{" "}
                </Button>
              </>
            )}
          </Card>
        </PaddingManager>
      </Wrap>
    </>
  ) : null;
};

export default PasswordConfirm;
