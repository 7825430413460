import { ButtonEnum, Country, Shipping, billingForm } from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { goToOrderCheckout } from "../../constants/routes";

import SubsectionTitle from "../SubsectionTitle";

import FormContainer from "../FormContainer";
import { getCountryList } from "../../api/countries";

import {
  setShippingRequest,
  updateShippingRequest,
} from "../../store/Shipping";
import { setBillingThunk, updateBillingThunk } from "../../store/Billing";
import { useNavigate } from "react-router";
import { billingSchema } from "../../utils/form";

type Props = {
  designId: string | undefined;
  orderId: string | undefined;
  productionId: string | undefined;
  shippingData: Shipping;
  shippingIsValid?: boolean;
  shippingIsDirty?: boolean;
  shippingFormRef: any;
};
const BillingForm = (props: Props) => {
  const {
    orderId,
    designId,
    productionId,
    shippingData,
    shippingIsDirty,
    shippingIsValid,
    shippingFormRef,
  } = props;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const [countries, setCountries] = useState([]);

  const order = useSelector((state: RootState) => state.order.data);
  const shipping = useSelector((state: RootState) => state.shipping.data);
  const billing = useSelector((state: RootState) => state.billing.data);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await getCountryList();

      setCountries(response.data);
    };
    fetchCountries();

    if (billing?.billing_id) {
      setFormData(billing);
    }
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    vat_number: "",
    address_line_1: "",
    address_line_2: "",
    postcode: "",
    province: "",
    city: "",
    country: "",
  });

  // HOOK-FORM
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<billingForm>({
    // @ts-ignore
    resolver: yupResolver(billingSchema),
    defaultValues: billing ? billing : formData,
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = (e: any) => {
    // e.preventDefault();
    if (!shippingIsDirty || !shippingIsValid || !isDirty || !isValid) {
      shippingFormRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    } else {
      if (order?.id !== undefined) {
        const shippingBody = {
          orderId: order.id,
          payload: shippingData,
        };
        const billingBody = {
          orderId: order.id,
          shippingId: shipping?.shipping_id,
          payload: getValues(),
        };
        billing?.billing_id
          ? dispatch(updateBillingThunk(billingBody))
          : dispatch(setBillingThunk(billingBody));

        shipping?.shipping_id
          ? dispatch(updateShippingRequest(shippingBody))
          : dispatch(setShippingRequest(shippingBody));

        if (designId && orderId && productionId)
          navigate(goToOrderCheckout(designId, orderId, productionId));
      } else {
        console.error("ERROR: no order id found");
      }
    }
  };

  return (
    <Wrap>
      <div className="billing-form">
        <SubsectionTitle title={"Billing address"} />
        <FormContainer>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">First name</label>
                <input
                  key="first_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("first_name")}
                />
                {errors["first_name"] && (
                  <small className="error-field">
                    {errors["first_name"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Last name</label>
                <input
                  key="last_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("last_name")}
                />
                {errors["last_name"] && (
                  <small className="error-field">
                    {errors["last_name"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Email</label>
                <input
                  key="email"
                  className={`size-selector-input`}
                  type="text"
                  {...register("email")}
                />
                {errors["email"] && (
                  <small className="error-field">
                    {errors["email"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Phone number</label>
                <input
                  key="phone"
                  className={`size-selector-input`}
                  type="text"
                  placeholder="+34123456789"
                  {...register("phone")}
                />
                {errors["phone"] && (
                  <small className="error-field">
                    {errors["phone"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Company name</label>
                <input
                  key="company_name"
                  className={`size-selector-input`}
                  type="text"
                  {...register("company_name")}
                />
                {errors["company_name"] && (
                  <small className="error-field">
                    {errors["company_name"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Vat number</label>
                <input
                  key="vat_number"
                  className={`size-selector-input`}
                  type="text"
                  {...register("vat_number")}
                />
                {errors["vat_number"] && (
                  <small className="error-field">
                    {errors["vat_number"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row full">
                <label className="label-sm">Address line 1</label>
                <input
                  key="address_line_1"
                  className={`size-selector-input`}
                  type="text"
                  {...register("address_line_1")}
                />
                {errors["address_line_1"] && (
                  <small className="error-field">
                    {errors["address_line_1"]?.message}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="input-row full">
                <label className="label-sm">Address line 2</label>
                <input
                  key="address_line_2"
                  className={`size-selector-input`}
                  type="text"
                  {...register("address_line_2")}
                />
                {errors["address_line_2"] && (
                  <small className="error-field">
                    {errors["address_line_2"]?.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">Postcode</label>
                <input
                  key="postcode"
                  className={`size-selector-input`}
                  type="text"
                  {...register("postcode")}
                />
                {errors["postcode"] && (
                  <small className="error-field">
                    {errors["postcode"]?.message}
                  </small>
                )}
              </div>
              <div className="input-row">
                <label className="label-sm">Province</label>
                <input
                  key="province"
                  className={`size-selector-input`}
                  type="text"
                  {...register("province")}
                />
                {errors["province"] && (
                  <small className="error-field">
                    {errors["province"]?.message}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="input-row">
                <label className="label-sm">City</label>
                <input
                  key="city"
                  className={`size-selector-input`}
                  type="text"
                  {...register("city")}
                />
                {errors["city"] && (
                  <small className="error-field">
                    {errors["city"]?.message}
                  </small>
                )}
              </div>

              <div className="input-row">
                <label className="label-sm">Country</label>
                <select
                  key="country"
                  className={`size-selector-input`}
                  {...register("country")}
                >
                  <option key="" value="">
                    Select yout country
                  </option>
                  <option key="es" value="ES">
                    Spain
                  </option>
                  {countries.map((country: Country) => (
                    <option key={country.iso2} value={country.iso2}>
                      {country.country}
                    </option>
                  ))}
                </select>

                {errors["country"] && (
                  <small className="error-field">
                    {errors["country"]?.message}
                  </small>
                )}
              </div>
            </div>
            <div className="form-row-button">
              <Button
                type={ButtonEnum.primary}
                onClick={handleSubmit(handleOnSubmit)}
                // disabled={
                //   !shippingIsDirty || !shippingIsValid || !isDirty || !isValid
                // }
              >
                Save and continue
              </Button>
            </div>
          </form>
        </FormContainer>
      </div>
    </Wrap>
  );
};

export default BillingForm;
