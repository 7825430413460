import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
  .production-designs-container {
    display: flex;
    gap: 16px;
    .print-preview {
      width: 120px;
      height: 120px;
      max-width: 120px;
      max-height: 120px;
      img {
        max-width: 120px;
        max-height: 120px;
      }
    }
    .production-designs-actions {
      text-align: left;
      .title {
        color: var(--gray130);
      }
      .actions {
        display: flex;
        gap: 8px;
      }
    }

    .sizes-container {
      margin-top: 16px;
      display: flex;
      gap: 16px;
      align-items: center;
      text-align: center;

      .size-row {
        color: var(--gray130);
        display: flex;
        flex-direction: column;
        gap: 4px;
        .size {
          color: var(--gray90);
        }
        .total {
          color: var(--gray130);
          font-family: "Helvetica-Bold";
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .production-designs-actions {
      .sizes-container {
        text-align: left;
      }
    }
  }
`;
