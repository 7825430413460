import { ForwardedRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch, RootState } from "../../store";
import { setProductionRequest } from "../../store/Production";
import { getProductionRangeAvailability } from "../../store/ProductionRange";
import { translateSize } from "../../utils/translate";
import {
  AlertEnum,
  ProductionRange,
  ProductionRangeAvailability,
} from "../../beans";
import Divider from "../Divider";
import Alert from "../Alert";
import { Wrap } from "./styles";
import LoadingOptions from "../LoadingOptions";
import { sizeSelectorSchema, sizesSelectorForm } from "../../utils/form";
import { calculateTotalProductionItems } from "../../utils/orders";

export type Ref = ForwardedRef<HTMLFormElement>;

type Props = {
  orderId: string;
  selectedProductionRange: ProductionRange | null;
};

const SizeSelector = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { orderId, selectedProductionRange } = props;

  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");

  const [availableSizes, setAvailableSizes] =
    useState<ProductionRangeAvailability>();
  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const productionState = useSelector((state: RootState) => state.production);
  const { data: production } = productionState;
  const selectedRange = useSelector(
    (state: RootState) => state.productionRange.selectedRange
  );

  const availableRange = useSelector(
    (state: RootState) => state.productionRange.availableRange
  );

  const minimum = useSelector(
    (state: RootState) => state.productionRange.selectedRange?.minimum
  );
  // HOOKS

  useEffect(() => {
    dispatch(getProductionRangeAvailability());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAvailableSizes();
  }, [selectedProductionRange, availableRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProductionRange) {
      reset({
        x_small: production?.sizes?.x_small,
        small: production?.sizes?.small,
        medium: production?.sizes?.medium,
        large: production?.sizes?.large,
        x_large: production?.sizes?.x_large,
      });

      getTotal(getValues());
    }
  }, [selectedProductionRange, production]); // eslint-disable-line react-hooks/exhaustive-deps

  const [formData, setFormData] = useState({
    x_small: production?.sizes?.x_small,
    small: production?.sizes?.small,
    medium: production?.sizes?.medium,
    large: production?.sizes?.large,
    x_large: production?.sizes?.x_large,
  });

  useEffect(() => {
    if (total && minimum) {
      total < minimum
        ? setError(
            `You select only ${total} items, please select at least ${minimum} items`
          )
        : setError("");
    }
  }, [total, minimum]);

  const getTotal = (data: any) => {
    const sum = calculateTotalProductionItems(data);
    setTotal(sum);
  };

  useEffect(() => {
    getTotal(formData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDesign && production?.id) {
      dispatch(
        setProductionRequest({
          productionId: production?.id,
          orderId: orderId,
          body: formData,
        })
      );
    }
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (data: any) => {
    setFormData(data);
    getTotal(data);
  };

  const setErrorClass = (k: any) => {
    let errorClass = "";
    if (
      touchedFields[k as keyof sizesSelectorForm] &&
      errors[k as keyof sizesSelectorForm]
    )
      errorClass = "error";

    return errorClass;
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<sizesSelectorForm>({
    resolver: yupResolver(sizeSelectorSchema),
    defaultValues: production?.sizes,
    shouldFocusError: false,
  });

  const onSubmit = (data: any) => {
    handleInputChange(data);
    getTotal(data);
  };

  const getAvailableSizes = () => {
    const range = availableRange.find(
      (range) => range.min === selectedRange?.minimum
    );
    if (range) setAvailableSizes(range);
  };

  return production?.sizes ? (
    <Wrap>
      <div className="sizes-editor">
        <form onChange={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="size-row">
              {Object.keys(production.sizes).map(function (
                key: any,
                i: number
              ) {
                if (
                  availableSizes &&
                  availableSizes[key as keyof ProductionRangeAvailability]
                )
                  return (
                    <div className="input-row" key={`${key}_${i}`}>
                      <label className="label-sm">{translateSize(key)}</label>
                      <input
                        className={`${setErrorClass(key)} size-selector-input`}
                        type="text"
                        {...register(key)}
                      />
                    </div>
                  );

                return null;
              })}
            </div>
            <Divider vertical />
            <div className="input-row" key={`total-size`}>
              <label className="label-sm">Total</label>
              <input
                className={
                  error ? `size-selector-input error` : `size-selector-input`
                }
                type="text"
                value={total}
                disabled
                onChange={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </form>
      </div>
      {Object.keys(errors).map(function (key: any) {
        return (
          <Alert type={AlertEnum.error}>
            {errors[key as keyof sizesSelectorForm]?.message}
          </Alert>
        );
      })}
      {error && <Alert type={AlertEnum.error}>{error}</Alert>}
    </Wrap>
  ) : (
    <LoadingOptions />
  );
};

export default SizeSelector;
