import { AlertEnum, ButtonEnum, ModalEnum } from "../../beans";
import Button from "../Button";
import Logo from "../Logo";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { loginRequest } from "../../store/Login";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Alert from "../Alert";
import { useNavigate } from "react-router";
import { hideModal, showModal } from "../../store/UI";
import FormContainer from "../FormContainer";
import { goToOrderShipping, routes } from "../../constants/routes";
import { loginForm, loginSchema } from "../../utils/form";
import { FullStory } from "@fullstory/browser";

const LoginForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const login = useSelector((state: RootState) => state.login);
  const modal = useSelector((state: RootState) => state.ui.modal);

  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const production = useSelector((state: RootState) => state.production.data);
  const order = useSelector((state: RootState) => state.order.data);
  const user = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    if (login.logged && modal.login) {
      if (userDesign?.id && order?.id && production?.id) {
        navigate(
          goToOrderShipping(
            userDesign?.id.toString(),
            order.id,
            production.id.toString()
          )
        );
      }
      dispatch(hideModal(ModalEnum.login));
    }
  }, [login]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO wait for user id from Colin
  useEffect(() => {
    if (user)
      FullStory("setIdentity", {
        uid: user.id.toString(),
        properties: {
          displayName: `${user.first_name} ${user.last_name}`,
          email: user.email,
        },
      });
  }, [user]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields, isDirty, isValid },
  } = useForm<loginForm>({
    resolver: yupResolver(loginSchema),
    defaultValues: formData,
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = () => {
    dispatch(loginRequest(formData));
  };

  const handleChangeForm = async (data: any) => {
    setFormData(data);
  };

  const handleResetPassword = () => {
    dispatch(hideModal(ModalEnum.login));
    navigate(routes.passwordReset);
  };

  const renderErrors = () => {
    if (login.error)
      for (let [key] of Object.entries(login.error)) {
        if (login?.error[key])
          return login.error[key]?.map((error: string) => (
            <Alert full type={AlertEnum.error}>
              {error}
            </Alert>
          ));
        else {
          return <Alert type={AlertEnum.error}>{login.error[key]}</Alert>;
        }
      }
  };

  return (
    <Wrap>
      <div className="logo">
        <Logo />
      </div>
      <FormContainer>
        <form
          onChange={handleSubmit(handleChangeForm)}
          onSubmit={handleSubmit(handleChangeForm)}
        >
          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">Email</label>
              <input
                key="email"
                className={`size-selector-input`}
                type="text"
                {...register("email")}
              />
              {touchedFields["email"] && errors["email"] && (
                <small className="error-field">
                  {errors["email"]?.message}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">Password</label>
              <input
                key="password"
                className={`size-selector-input`}
                type="password"
                {...register("password")}
              />
              {errors["password"] && (
                <small className="error-field">
                  {touchedFields["password"] && errors["password"]?.message}
                </small>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="input-row full reset-pwd">
              <Button type={ButtonEnum.link} onClick={handleResetPassword}>
                Forgot your password?
              </Button>
            </div>
          </div>

          <div className="error-row">{renderErrors()}</div>
          <div className="form-row">
            <Button
              type={ButtonEnum.primary}
              onClick={handleSubmit(handleOnSubmit)}
              disabled={!isDirty || !isValid}
              loading={login.loading}
            >
              Sign in
            </Button>
          </div>
        </form>
        <div className="description label-lg">
          By continuing, you agree to our{" "}
          <a
            href="https://fabbric.com/policies/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and acknowledge you’ve read our{" "}
          <a
            href="https://fabbric.com/policies/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div className="description label-lg">
          Don’t have an account?{" "}
          <Button
            onClick={() => {
              dispatch(hideModal(ModalEnum.login));
              dispatch(showModal(ModalEnum.registration));
            }}
            type={ButtonEnum.link}
          >
            Sign up
          </Button>
        </div>
      </FormContainer>
    </Wrap>
  );
};

export default LoginForm;
