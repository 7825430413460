import { PrintLocation } from "../beans";

export function translatePrintLocation(location: string) {
  switch (location.toString()) {
    case "front_center":
      return "Center chest";
    case "full_back":
      return "Full back";
    case "front_left":
      return "Left chest";
    default:
      return "unkown position";
  }
}
export function translatePrintLocationById(
  locationId: string,
  printLocations: PrintLocation[]
) {
  const location = printLocations.find(
    (location) => location.id === locationId
  );
  if (location) return translatePrintLocation(location?.placement);
  else {
    return "unkown position";
  }
}

export function getPrintLocationById(
  locationId: string,
  printLocations: PrintLocation[]
) {
  return printLocations.find((location) => location.id === locationId);
}

export function getPrintLocationIdFromName(
  locationPlacement: string,
  printLocations: PrintLocation[]
) {
  const location = printLocations.find(
    (location) => location.placement === locationPlacement
  );
  if (location) return location.id;
}

export const translateSize = (size: string) => {
  switch (size) {
    case "xx_small":
      return "XXS";
    case "x_small":
      return "XS";
    case "small":
      return "S";
    case "medium":
      return "M";
    case "large":
      return "L";
    case "x_large":
      return "XL";
    case "xx_large":
      return "XXL";
    case "xxx_large":
      return "XXXL";
  }
};

export function translatePrintType(type: string) {
  switch (type.toString()) {
    case "screen_print":
      return "Screen print";
    case "embroidery":
      return "Embroidery";
    case "dtf":
      return "DTF";
    default:
      return "unkown position";
  }
}
