import sizeMe from "react-sizeme";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { cages } from "../../constants/cages";
import { getProperty } from "../../utils/render";
import { PrintLocation } from "../../beans";
import { convertCm2Px } from "../../utils/sizes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { addCage } from "../../store/UI";

type Props = {
  printLocation: PrintLocation;
  show?: boolean;
  htmlId: number;
  size?: any;
};
const ArtworkCage = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { printLocation, show, htmlId, size } = props;
  const { width, height } = size;

  useEffect(() => {
    let conversionUnit = width
      ? convertCm2Px(printLocation.real_width_cm, width)
      : 0;

    if (width > 0 && height > 0) {
      dispatch(
        addCage({
          id: htmlId,
          width: width,
          height: height,
          conversion_unit: conversionUnit,
        })
      );
    }
  }, [width, height, htmlId]);
  return (
    <Wrap
      className={printLocation.placement}
      $show={show}
      data-width={width}
      data-height={height}
    >
      <Icon
        icon={getProperty(cages, printLocation.placement)}
        id={`cage_${htmlId}`}
        data-width={width}
        data-height={height}
      />
    </Wrap>
  );
};

export default sizeMe({ monitorHeight: true })(ArtworkCage);
