import styled from "styled-components";
import { rotate } from "../../design-system/animations";

export const Wrap = styled.div`
  position: absolute;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  svg {
    animation: ${rotate} 2s linear infinite;
    fill: #7745ff;
    path {
      fill: #7745ff;
    }
  }
`;
