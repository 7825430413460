import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .design-name {
    position: relative;
    background: white;
    width: 100%;
    padding-top: 16px;
  }

  .actions {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 16px;
    bottom: 0px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .design-preview-card {
    position: relative;
    height: 100%;

    .artwork {
      position: relative;
    }

    &.multiple {
      .artwork {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    &:hover {
      .actions {
        opacity: 1;
        bottom: 20px;
      }
      &.multiple {
        .artwork:nth-of-type(1) {
          display: none;
        }
        .artwork:nth-of-type(2) {
          display: block;
        }
      }
    }
  }
`;
