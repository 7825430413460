import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Item } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface ItemState {
  data: Item[] | null;
  error: null | any;
  selected: Item | null;
  loading: boolean;
  loaded: boolean;
}

const initialState: ItemState = {
  data: [],
  error: null,
  selected: null,
  loading: false,
  loaded: false,
};

export const getItemThunk = createAsyncThunk("item/request", async () => {
  const response = await fetch(`${apiUrl}/designs/item/`, {
    headers: { accept: "application/json" },
  });

  return await response.json();
});

export const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    selectItem: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItemThunk.pending, (state) => {
      state.data = [];
      state.loading = true;
    });
    builder.addCase(getItemThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(getItemThunk.rejected, (state, action) => {
      state.error = action.error.message!;
      state.loaded = true;
      state.loading = false;
    });
  },
});

export const { selectItem } = itemSlice.actions;

export default itemSlice.reducer;
