export const priceFormat = (price: string | number) => {
  if (!price) return 0;
  return Number(price).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const dateFormat = (d: string) => {
  if (!d) return "uknown";
  const date = new Date(d);
  return date.toLocaleDateString("es-ES");
};
