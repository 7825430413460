import React from "react";
import { Wrap } from "./styles";

type Props = {
  title: string;
  children?: React.ReactNode;
};
const SubsectionTitle = (props: Props) => {
  return (
    <Wrap>
      <div className="title title-sm">{props.title}</div>
      <div className="action">{props.children}</div>
    </Wrap>
  );
};

export default SubsectionTitle;
