import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
  background: var(--gray20);
  height: 100%;

  .page-containers {
    padding: 24px 0;
    display: flex;
    gap: 48px;
    height: 100%;
    .page-content {
      min-width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .page-containers {
      flex-direction: column;
    }
  }
`;
