import { MouseEventHandler } from "react";
import { ItemRender } from "../../beans";
import { Wrap } from "./styles";

type Props = {
  canva: ItemRender;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
const RenderThumbnail = (props: Props) => {
  const { selected, canva } = props;
  const { image } = canva;

  return (
    <Wrap $selected={selected} onClick={props.onClick}>
      <img className="thumb" src={image} alt="" />
    </Wrap>
  );
};

export default RenderThumbnail;
