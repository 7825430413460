import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonEnum, Item } from "../../beans";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header";
import PaddingManager from "../../components/PaddingManager";
import { AppDispatch, RootState } from "../../store";
import { getItemThunk, selectItem } from "../../store/Item";
import { Wrap } from "./styles";
import ProductCard from "../../components/ProductCard";
import { resetUserDesign, setUserDesignThunk } from "../../store/UserDesign";
import { setCSFRToken } from "../../api/csfr";
import { goToEditDesign } from "../../constants/routes";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";
import { resetOrders } from "../../store/Order";
import { getPrintLocationThunk } from "../../store/PrintLocation";
import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import { resetTab } from "../../store/UI";
import { resetPrintType } from "../../store/PrintType";

import LoadingCards from "../../components/LoadingCards";
import Button from "../../components/Button";

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const itemsState = useSelector((state: RootState) => state.items);
  const { data: items } = itemsState;
  const userDesign = useSelector((state: RootState) => state.userDesign.data);

  // HOOKS
  useEffect(() => {
    dispatch(getPrintLocationThunk());
    dispatch(resetProduction());
    dispatch(resetOrders());
    dispatch(resetUserDesign());
    dispatch(resetFabric());
    dispatch(resetTab());
    dispatch(resetPrintType());
    dispatch(resetShipping());
    dispatch(resetBilling());
  }, [dispatch]);

  useEffect(() => {
    setCSFRToken();
    dispatch(getItemThunk());
  }, [dispatch]);

  useEffect(() => {
    if (userDesign?.id) navigate(goToEditDesign(userDesign.id));
  }, [userDesign, navigate]);

  const handleClick = (item: Item) => {
    dispatch(selectItem(item.id));
    dispatch(setUserDesignThunk(item.id));
  };

  return (
    <>
      <Header />
      <Wrap>
        <PaddingManager>
          <div className="container">
            <div className="title title-lg">
              Select the product you want to design
            </div>

            {items && itemsState.loaded ? (
              <div className="product-grid">
                {items.map((item: Item) => (
                  <ProductCard
                    item={item}
                    onClick={() => handleClick(item)}
                    key={`product_${item.id}`}
                  />
                ))}
              </div>
            ) : (
              <LoadingCards />
            )}
          </div>

          <div className="custom-product-link hs-cta-trigger-button hs-cta-trigger-button-107720768199">
            <Button type={ButtonEnum.link} onClick={() => {}}>
              Can't find exactly what you're looking for?
            </Button>
          </div>
        </PaddingManager>
      </Wrap>
    </>
  );
};

export default Home;
