import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
import { loading } from "../../design-system/animations";

export const Wrap = styled.div`
  .product-grid {
    margin: auto;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }
  .block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .card {
      width: 100%;
      height: 300px;
      padding: 0;
    }
    .title {
      width: 100%;
      height: 20px;
      padding: 0;
    }
  }

  .animate {
    color: transparent;
    background: linear-gradient(
      100deg,
      var(--gray20) 30%,
      var(--gray30) 50%,
      var(--gray20) 70%
    );
    background-size: 400%;
    background-position: 100% 50%;
    animation: ${loading} 1.8s ease-in-out infinite;
  }

  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    .product-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .product-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
