import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  align-items: start;
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;

  gap: 16px;
  .artwork-image {
    background: var(--gray20);

    img {
      width: 100px;
    }
  }
  .artwork-details {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
`;
