export enum CreateOrderActionEnum {
  LIST_ORDER_REQUEST = "order/listOrderRequest",
  LIST_ORDER_SUCCESS = "order/listOrderSuccess",
  LIST_ORDER_FAILURE = "order/listOrderFailure",
  CREATE_ORDER_REQUEST = "order/createOrderRequest",
  CREATE_ORDER_SUCCESS = "order/createOrderSuccess",
  CREATE_ORDER_FAILURE = "order/createOrderFailure",
  GET_ORDER_BY_ID_REQUEST = "order/getOrderSummaryRequest",
  GET_ORDER_BY_ID_SUCCESS = "order/getOrderSummarySuccess",
  GET_ORDER_BY_ID_FAILURE = "order/getOrderSummaryFailure",
  GET_ORDER_SUMMARY_REQUEST = "order/getOrderSummaryRequest",
  GET_ORDER_SUMMARY_SUCCESS = "order/getOrderSummarySuccess",
  GET_ORDER_SUMMARY_FAILURE = "order/getOrderSummaryFailure",
  GET_ORDER_COMPLETED_REQUEST = "order/getOrderCompletedRequest",
  GET_ORDER_COMPLETED_SUCCESS = "order/getOrderCompletedSuccess",
  GET_ORDER_COMPLETED_FAILURE = "order/getOrderCompletedFailure",
}
