import styled from "styled-components";

export const Wrap = styled.div`
  position: relative;
  .fabric-swatches {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
`;
