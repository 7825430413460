import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
  position: relative;
  display: flex;
  width: 208px;
  width: calc(50% - 8px);
  border-radius: 4px;
  background: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  border: ${(props) =>
    props.$selected
      ? "1px solid var(--purple100);"
      : "1px solid var(--gray30);"};

  &:hover {
    border: ${(props) =>
      props.$selected
        ? "1px solid var(--purple100);"
        : props.$disabled
          ? "1px solid var(--gray30);"
          : "1px solid var(--gray90);"};
  }

  .card-content {
    padding: 16px;
    display: flex;
    width: 100%;

    .label {
      color: ${(props) =>
        props.$disabled ? "var(--gray30);" : " var(--gray90);"};
    }
    .title {
      color: ${(props) =>
        props.$disabled ? "var(--gray30);" : " var(--gray130);"};
    }
  }

  .action-info-button {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  &.simple {
    justify-content: center;
    .card-content {
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  &.expanded {
    .card-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .price {
        text-align: right;
      }
    }
  }

  &.simple {
    justify-content: center;

    .card-content {
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &.expanded {
    .card-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .price {
        text-align: right;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    width: calc(33% - 16px);
  }
`;
