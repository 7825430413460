import { Wrap } from "./styles";
import { RootState, AppDispatch } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Fabrics as FabricsType, Fabric as FabricType } from "../../beans";
import Swatch from "../Swatch";
import SubsectionTitle from "../SubsectionTitle";
import { selectFabric, selectFabricColor } from "../../store/Fabric";
import Divider from "../Divider";
import LoadingOptions from "../LoadingOptions";
import { useEffect } from "react";

const Fabric = () => {
  const dispatch = useDispatch<AppDispatch>();

  const fabrics = useSelector((state: RootState) => state.fabrics.data);

  const selectedFabric = useSelector(
    (state: RootState) => state.fabrics.selectedFabric
  );
  const selectedFabricColor = useSelector(
    (state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
  );

  const handleSelectFabric = (fabric: FabricsType) => {
    dispatch(selectFabric(fabric));
    dispatch(selectFabricColor(fabric.coloredfabric_set[0]));
  };

  const handleSelectFabricColor = (fabric: FabricType) => {
    dispatch(selectFabricColor(fabric));
  };

  useEffect(() => {
    if (selectedFabric && !selectedFabricColor)
      dispatch(selectFabricColor(selectedFabric?.coloredfabric_set[0]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap>
      {fabrics ? (
        <>
          <SubsectionTitle title="Fabric" />
          <div className="fabric-swatches">
            {fabrics.map((fabric: FabricsType) => {
              return (
                <Swatch
                  info
                  title={fabric.name}
                  description={fabric.description}
                  onClick={() => handleSelectFabric(fabric)}
                  image={fabric.fabric_image_url}
                  key={fabric.code}
                  code={fabric.code}
                  selected={selectedFabric?.code === fabric.code}
                />
              );
            })}
          </div>
          <Divider />
          {selectedFabric && selectedFabricColor ? (
            <>
              <SubsectionTitle title="Colour" />
              <div className="fabric-swatches">
                {selectedFabric.coloredfabric_set.map((coloredFabric) => {
                  return (
                    <Swatch
                      onClick={() => handleSelectFabricColor(coloredFabric)}
                      color={coloredFabric.color}
                      title={coloredFabric.color.name}
                      image={coloredFabric.url}
                      key={coloredFabric.id}
                      selected={coloredFabric?.id === selectedFabricColor.id}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <LoadingOptions />
          )}
        </>
      ) : (
        <LoadingOptions />
      )}
    </Wrap>
  );
};

export default Fabric;
