import {
  ArtworkDetail,
  ItemRender,
  PrintLocation,
  PrintPlacement,
} from "../beans";

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function getCanvaFromView(
  canvas: ItemRender[],
  view?: string,
  isAvatar?: boolean
) {
  switch (view) {
    case "front_center":
    case "front_left":
      return isAvatar
        ? canvas.find((canva) => canva.view === "avatar_front")
        : canvas.find((canva) => canva.view === "front");

    case "full_back":
      return isAvatar
        ? canvas.find((canva) => canva.view === "avatar_back")
        : canvas.find((canva) => canva.view === "back");
    default:
      return canvas.find((canva) => canva.view === "front");
  }
}

export function getPrintLocationFromArtworkDetail(
  artworkDetail: ArtworkDetail[] | undefined
) {
  if (!artworkDetail) return null;
  return artworkDetail.map((artwork: ArtworkDetail) => artwork.location);
}

export function getPrintLocationFromCanva(
  canva: ItemRender,
  printlocations: PrintLocation[],
  selectedPrintLocation: string,
  artworkDetail: ArtworkDetail[] | undefined
) {
  if (!canva) return;
  const artworkLocations = getPrintLocationFromArtworkDetail(artworkDetail);
  const hasFrontCenter = artworkLocations?.find(
    (location) => Number(location) === PrintPlacement.front_center
  );
  const hasLeftCenter = artworkLocations?.find(
    (location) => Number(location) === PrintPlacement.left_center
  );

  switch (canva.view) {
    case "front":
    case "avatar_front":
      if (hasFrontCenter) {
        return printlocations.find(
          (location) => location.placement === "front_center"
        );
      }
      if (hasLeftCenter) {
        return printlocations.find(
          (location) => location.placement === "front_left"
        );
      } else if (selectedPrintLocation === "front_left") {
        return printlocations.find(
          (location) => location.placement === "front_left"
        );
      } else
        return printlocations.find(
          (location) => location.placement === "front_center"
        );

    case "avatar_back":
    case "back":
      return printlocations.find(
        (location) => location.placement === "full_back"
      );

    default:
      return printlocations.find(
        (location) => location.placement === "front_center"
      );
  }
}
