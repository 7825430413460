import { countryApi } from "../constants/endpoints";
import { getAuthHeader } from "./auth";

export const getCountryList = async () => {
  const headers = getAuthHeader();
  const response = await fetch(`${countryApi}/countries`, {
    headers: headers,
  });
  return await response.json();
};
