import { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { ActionCardEnum } from "../../beans";

import ActionCard from "../ActionCard";
import { setProductionRequest } from "../../store/Production";

type Props = {
  orderId: string;
};

const SampleSizeSelector = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [sampleSize, setSampleSize] = useState<string>("small");
  const production = useSelector((state: RootState) => state.production.data);

  useEffect(() => {
    const initialSampleProductionPayload = {
      small: 0,
      medium: 0,
      large: 0,
      x_large: 0,
      sample: false,
    };
    (initialSampleProductionPayload as any)[sampleSize] = 1;
    (initialSampleProductionPayload as any)["sample"] = true;

    if (production?.id) {
      dispatch(
        setProductionRequest({
          productionId: production.id,
          orderId: props.orderId,
          body: initialSampleProductionPayload,
        })
      );
    }
  }, [sampleSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap>
      <div className="size-editor">
        <ActionCard
          title="S"
          type={ActionCardEnum.simple}
          selected={sampleSize === "small"}
          onClick={() => setSampleSize("small")}
        />
        <ActionCard
          title="M"
          type={ActionCardEnum.simple}
          selected={sampleSize === "medium"}
          onClick={() => setSampleSize("medium")}
        />
        <ActionCard
          title="L"
          type={ActionCardEnum.simple}
          selected={sampleSize === "large"}
          onClick={() => setSampleSize("large")}
        />
        <ActionCard
          title="XL"
          type={ActionCardEnum.simple}
          selected={sampleSize === "x_large"}
          onClick={() => setSampleSize("x_large")}
        />
      </div>
    </Wrap>
  );
};

export default SampleSizeSelector;
