import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  background: var(--gray20);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 4px;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    display: none;
    &.loaded {
      display: block;
    }
  }
`;
