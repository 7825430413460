import styled from "styled-components";
import { rotate } from "../../design-system/animations";
export const Wrap = styled.div<{ $height?: string; $width?: string }>`
  width: ${(props) => (props.$width ? `${props.$width}` : "100%")};
  height: ${(props) => (props.$height ? `${props.$height}` : "100%")};

  .moveable-control.moveable-direction {
    background: var(--purple100);
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
    border: 1px solid #fff;
  }

  .artowork-image {
    height: 16px;
    width: 16px;
  }

  .loading-artwork {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      animation: ${rotate} 2s linear infinite;
      height: 16px;
      width: 16px;
    }
  }
`;
