import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--headerHeight));
  justify-content: center;
  text-align: center;
  margin: auto;
  gap: 24px;
  max-width: 555px;
  .title {
    color: var(--purple100);
    text-align: center;
    font-family: "Helvetica Bold";
    font-size: 83.23px;
    font-style: normal;
    line-height: 99.876px; /* 120% */
  }
  .action {
    margin: auto;
    display: flex;
    justify-content: center;
  }
`;
