import { ButtonEnum } from "../../beans";
import { Wrap } from "./styles";
import { MouseEventHandler } from "react";
import { loadingLogo } from "../../constants/icons";
import Icon from "../Icon";

type Props = {
  id?: string;
  type: ButtonEnum;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
};
const Button = (props: Props) => {
  return (
    <Wrap
      id={props.id}
      disabled={props.disabled}
      onClick={props.onClick}
      className={props.loading ? `loading ${props.type}` : `${props.type}`}
    >
      {props.loading && (
        <div className="loading-spinner">
          <Icon icon={loadingLogo} />
        </div>
      )}
      {props.children}
    </Wrap>
  );
};

export default Button;
