import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    padding: 0 16px;
  }
`;
