import styled from "styled-components";

export const Wrap = styled.button`
  display: flex;
  min-width: 70px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border: 1px solid transparent;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 19.2px; /* 120% */
  color: var(--gray90);
  cursor: pointer;
  &:hover {
    color: var(--gray110);
    border-bottom: 1px solid var(--gray110);
  }

  &.selected {
    color: var(--purple100);
    border-bottom: 1px solid var(--purple100);
  }
`;
