import { MouseEventHandler } from "react";
import { ButtonEnum, Item } from "../../beans";
import Button from "../Button";

import ProductImage from "../ProductImage";
import { Wrap } from "./styles";

type Props = {
  size?: string;
  item: Item;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
const ProductCard = (props: Props) => {
  const { name, id, default_render } = props.item;

  return (
    <Wrap onClick={props.onClick} key={id} className={props.size}>
      <div className="product-image-container">
        {default_render && <ProductImage src={default_render} alt={name} />}
        <Button type={ButtonEnum.primary}>Select and customise</Button>
      </div>
      <div className="product-label title-sm">{name}</div>
    </Wrap>
  );
};

export default ProductCard;
