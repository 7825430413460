import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionRange, ProductionRangeAvailability } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface ProductionRangeState {
  data: ProductionRange[];
  error: null | any;
  availableRange: ProductionRangeAvailability[] | [];
  sampleRange: ProductionRange | null;
  selectedRange: ProductionRange | null;
}

const initialState: ProductionRangeState = {
  data: [],
  error: null,
  availableRange: [],
  sampleRange: null,
  selectedRange: null,
};

export const getProductionRangeThunk = createAsyncThunk(
  "production-range/request",
  async (userDesignId: number) => {
    const response = await fetch(
      `${apiUrl}/designs/userdesigns/${userDesignId}/productionrangecost/starting_from_prices/`,
      {
        headers: { accept: "application/json" },
      }
    );

    return await response.json();
  }
);

export const getProductionRangeAvailability = createAsyncThunk(
  "production-range-availability/request",
  async () => {
    const response = await fetch(`${apiUrl}/designs/productionrange/`, {
      headers: { accept: "application/json" },
    });

    return await response.json();
  }
);

export const productionRangeSlice = createSlice({
  name: "productionRange",
  initialState,
  reducers: {
    selectProductionRange: (state, action: PayloadAction<any>) => {
      state.selectedRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductionRangeThunk.pending, (state) => {
      state.data = [];
    });
    builder.addCase(getProductionRangeThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getProductionRangeThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });
    builder.addCase(getProductionRangeAvailability.pending, (state) => {
      state.availableRange = [];
    });
    builder.addCase(
      getProductionRangeAvailability.fulfilled,
      (state, action) => {
        state.sampleRange = action.payload[0];
        state.availableRange = action.payload;
      }
    );
    builder.addCase(
      getProductionRangeAvailability.rejected,
      (state, action) => {
        state.error = action.error.message!;
      }
    );
  },
});

export const { selectProductionRange } = productionRangeSlice.actions;
export default productionRangeSlice.reducer;
