import styled from "styled-components";

export const Wrap = styled.div`
  path {
    fill: var(--purple100);
  }
  &:hover {
    path {
      fill: var(--purple110);
    }
  }
`;
