import { ButtonEnum, ModalEnum } from "../../beans";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { close } from "../../constants/icons";
import { MouseEventHandler, ReactElement } from "react";
import { hideModal } from "../../store/UI";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { warning } from "../../constants/icons";
type Props = {
  type: ModalEnum;
  error?: boolean;
  title?: string;
  content?: string | ReactElement;
  open?: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
};
const Modal = (props: Props) => {
  const { open, title, content, error, type, onClose } = props;
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Wrap $open={open} $error={error}>
      <div className="modal-container">
        <div className="modal-header">
          <div className="title title-sm">
            {error && <Icon icon={warning} />}
            {title}
          </div>
          <div className="action">
            <Button
              type={ButtonEnum.icon}
              onClick={(e) => {
                onClose ? onClose(e) : dispatch(hideModal(type));
              }}
            >
              <Icon icon={close} />
            </Button>
          </div>
        </div>
        <div className="modal-content">{content}</div>
      </div>
    </Wrap>
  );
};

export default Modal;
