import { MouseEventHandler, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  hideActionModal,
  listInfoBoxContentsRequest,
  resetActionModal,
  resetErrorModal,
  resetTab,
  showActionModal,
  showModal,
} from "../../store/UI";
import { userRequest } from "../../store/User";
import { logoutRequest, isLoggedRequest } from "../../store/Login";
import { ButtonEnum, ModalActionButton, ModalEnum } from "../../beans";
import { routes } from "../../constants/routes";
import { close, account, signout } from "../../constants/icons";
import RegistrationForm from "../RegistrationForm";
import PaddingManager from "../PaddingManager";
import LoginForm from "../LoginForm";
import Logo from "../Logo";
import Modal from "../Modal";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import InfoBox from "../InfoBox";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";
import { resetOrders } from "../../store/Order";
import { getPrintLocationThunk } from "../../store/PrintLocation";
import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import { resetUserDesign } from "../../store/UserDesign";

type Props = {
  product?: boolean;
  productName?: string;
  productType?: string;
  onClose?: MouseEventHandler<HTMLButtonElement>;
};

const Header = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { productName, productType, product } = props;
  const ui = useSelector((state: RootState) => state.ui);
  const user = useSelector((state: RootState) => state.user.data);
  const isLogged = useSelector((state: RootState) => state.login.logged);

  useEffect(() => {
    dispatch(isLoggedRequest());
    if (isLogged && !user) {
      dispatch(userRequest());
    }
  }, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(listInfoBoxContentsRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToHome = () => {
    dispatch(getPrintLocationThunk());
    dispatch(resetProduction());
    dispatch(resetOrders());
    dispatch(resetUserDesign());
    dispatch(resetFabric());
    dispatch(resetTab());
    dispatch(resetShipping());
    dispatch(resetBilling());
    navigate(routes.home);
  };

  const closeHeader = () => {
    if (isLogged) goToHome();
    else {
      dispatch(
        showActionModal({
          title: "Delete design",
          error:
            '<div class="title-sm">Are you sure you want to delete this design?</div><div class="label-lg"><p>Your changes will be lost unless you log in.</p></div>',
          actions: [
            {
              type: ButtonEnum.secondary,
              onClick: () => {
                dispatch(hideActionModal());
                goToHome();
              },
              label: "Close without saving",
            },
            {
              type: ButtonEnum.primary,
              onClick: () => {
                dispatch(hideActionModal());
                dispatch(showModal(ModalEnum.login));
              },
              label: "Login to save",
            },
          ],
        })
      );
    }
  };

  const renderLoginModal = () => {
    return (
      <Modal
        type={ModalEnum.login}
        key={"modal_login"}
        open={ui.modal.login}
        title="Sign In"
        content={<LoginForm />}
      ></Modal>
    );
  };

  const renderRegistrationModal = () => {
    return (
      <Modal
        type={ModalEnum.registration}
        key={"modal_registration"}
        open={ui.modal.registration}
        title="Create account"
        content={<RegistrationForm />}
      ></Modal>
    );
  };

  const renderInfoBox = () => {
    return (
      <InfoBox
        open={ui.infoBox.open}
        title={ui.infoBox.active.title}
        content={ui.infoBox.active.content}
        image={ui.infoBox.active.image}
      />
    );
  };

  const renderModalError = () => {
    return (
      <Modal
        type={ModalEnum.error}
        key="errorModal"
        onClose={() => dispatch(resetErrorModal())}
        open={ui.errorModal.open}
        title="Error"
        error
        content={
          <p dangerouslySetInnerHTML={{ __html: ui.errorModal.error }}></p>
        }
      />
    );
  };

  const renderActionModal = () => {
    return (
      <Modal
        type={ui.actionModal.type}
        key="actionModal"
        onClose={() => dispatch(resetActionModal())}
        open={ui.actionModal.open}
        title={ui.actionModal.title || "Attention"}
        error
        content={
          <>
            <div
              dangerouslySetInnerHTML={{ __html: ui.actionModal.error }}
            ></div>
            <footer>
              {ui.actionModal.actions.map((action: ModalActionButton) => (
                <Button
                  type={action.type}
                  onClick={action.onClick}
                  key={action.label}
                >
                  {action.label}
                </Button>
              ))}
            </footer>
          </>
        }
      />
    );
  };

  return (
    <>
      <Wrap className={product ? "product" : ""} role="header">
        <PaddingManager>
          <div className="header-container">
            <div className="logo">
              <Button type={ButtonEnum.link} onClick={() => goToHome()}>
                <Logo />
              </Button>
            </div>
            <div className="content">
              {product ? (
                <>
                  {productType || productName ? (
                    <div className="product-details">
                      {productName && (
                        <div className="product-name title-sm">
                          {productName}
                        </div>
                      )}
                      {productType && (
                        <>
                          <div className="product-details-divider"></div>
                          <div className="product-type body-sm">
                            {" "}
                            {productType}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {isLogged && (
                    <ul role="navigation">
                      <li>
                        <a
                          onClick={() => navigate(routes.design)}
                          className={
                            window.location.pathname === routes.design
                              ? "active"
                              : ""
                          }
                        >
                          Design
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => navigate(routes.production)}
                          className={
                            window.location.pathname === routes.production
                              ? "active"
                              : ""
                          }
                        >
                          Production
                        </a>
                      </li>
                    </ul>
                  )}
                </>
              )}
            </div>

            <div className="action">
              {product ? (
                <Button type={ButtonEnum.icon} onClick={() => closeHeader()}>
                  <Icon icon={close} />
                </Button>
              ) : !isLogged ? (
                <>
                  <Button
                    type={ButtonEnum.icon}
                    onClick={() => {
                      dispatch(showModal(ModalEnum.login));
                    }}
                  >
                    <Icon icon={account} />
                    Sign in
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type={ButtonEnum.icon}
                    onClick={() => {
                      dispatch(showModal(ModalEnum.login));
                    }}
                  >
                    <Icon icon={account} />
                    My Account
                  </Button>
                  <div className="logged-actions">
                    <div className="action-item-text">
                      Hi, {user?.first_name}
                    </div>
                    <div className="action-item">
                      <Button
                        type={ButtonEnum.icon}
                        onClick={() => {
                          dispatch(logoutRequest());
                        }}
                      >
                        <Icon icon={signout} />
                        Sign out
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </PaddingManager>
      </Wrap>
      {/* MODALS  */}
      {ui.modal.login && renderLoginModal()}
      {ui.modal.registration && renderRegistrationModal()}
      {ui.errorModal.open && renderModalError()}
      {ui.actionModal.open && renderActionModal()}

      {renderInfoBox()}
    </>
  );
};

export default Header;
