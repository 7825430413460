import styled from "styled-components";

export const Wrap = styled.div`
  background: var(--gray20);
  min-height: calc(100vh - var(--headerHeight));
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 512px;
    width: 100%;
  }
`;
