import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { OrderList } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";
import { CreateOrderActionEnum } from "./actions";
export interface OrderState {
  data: null | any; // TODO type better
  summary: null | any;
  confirmation: null | any;
  orderList: OrderList[] | null;
  error: null | any;
  loading: boolean;
  loaded: boolean;
}

const initialState: OrderState = {
  data: null,
  error: null,
  summary: null,
  orderList: null,
  confirmation: null,
  loading: false,
  loaded: false,
};

// SAGAS
function* createOrderSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
      method: "POST",
      body: JSON.stringify({
        production_id: action.payload,
      }),
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();
    if (response.status === 200 || response.status === 201) {
      yield put(createOrderSuccess(data));
    } else {
      yield put(createOrderFailure(data));
    }
  } catch (error: any) {
    // console.error("ERROR", error);
  }
}

function* getOrderById(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(getOrderByIdSuccess(data));
    } else {
      yield put(getOrderByIdFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderByIdFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* getOrderCompletedSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/confirmation/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(getOrderCompletedSuccess(data));
      yield put(createOrderSuccess({ id: data.id }));
    } else {
      yield put(getOrderCompletedFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderCompletedFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* getOrderSummarySaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();

    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/summary/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();
    if (response.status === 200 || response.status === 201) {
      yield put(getOrderSummarySuccess(data));
    } else {
      yield put(getOrderSummaryFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderSummaryFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* listOrderSaga(): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();

    if (response.ok) {
      yield put(listOrderSuccess(data));
    } else {
      yield put(listOrderFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: any) {
    // console.error("ERROR", error);
  }
}

export const orderSlice = createSlice({
  name: "order",
  initialState,

  reducers: {
    resetOrders: (state) => (state = initialState),
    listOrderRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    listOrderSuccess: (state, action: PayloadAction<any>) => {
      state.orderList = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    listOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    createOrderRequest: (state, _action: PayloadAction<any>) => {
      state.error = initialState.error;
      state.loading = true;
    },
    createOrderSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    createOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getOrderByIdRequest: (state, _action: PayloadAction<any>) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    getOrderByIdSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderByIdFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getOrderSummaryRequest: (state, _action: PayloadAction<any>) => {
      state.error = initialState.error;
      state.loading = true;
    },
    getOrderSummarySuccess: (state, action: PayloadAction<any>) => {
      state.summary = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderSummaryFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getOrderCompletedRequest: (state, _action: PayloadAction<any>) => {
      state.error = initialState.error;
      state.loading = true;
    },
    getOrderCompletedSuccess: (state, action: PayloadAction<any>) => {
      state.confirmation = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderCompletedFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
  },
});

// TODO define actions better in actions.tsx
export const {
  resetOrders,
  listOrderRequest,
  listOrderSuccess,
  listOrderFailure,
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  getOrderByIdRequest,
  getOrderByIdSuccess,
  getOrderByIdFailure,
  getOrderSummaryRequest,
  getOrderSummarySuccess,
  getOrderSummaryFailure,
  getOrderCompletedRequest,
  getOrderCompletedSuccess,
  getOrderCompletedFailure,
} = orderSlice.actions;

export default orderSlice.reducer;

export function* sagas() {
  yield takeEvery(CreateOrderActionEnum.CREATE_ORDER_REQUEST, createOrderSaga);
  yield takeEvery(CreateOrderActionEnum.GET_ORDER_BY_ID_REQUEST, getOrderById);
  yield takeEvery(
    CreateOrderActionEnum.GET_ORDER_SUMMARY_REQUEST,
    getOrderSummarySaga
  );
  yield takeEvery(
    CreateOrderActionEnum.GET_ORDER_COMPLETED_REQUEST,
    getOrderCompletedSaga
  );
  yield takeEvery(CreateOrderActionEnum.LIST_ORDER_REQUEST, listOrderSaga);
}
