import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .form-row {
      display: flex;
      gap: 16px;
      .input-row {
        width: 50%;
        justify-content: flex-start;
        label {
          align-self: start;
        }
        input,
        select {
          width: 100%;

          &:has(+ small) {
            border: 1px solid var(--red100);
          }
        }
        .error-field {
          color: var(--red110);
          width: 100%;
          font-weight: bold;
        }
        &.full {
          width: 100%;
        }

        &.checkbox {
          flex-direction: row-reverse;
          justify-content: start;
          align-items: center;
          input {
            width: auto;
          }
          label {
            align-self: center;
          }
        }
      }

      button {
        width: 100%;

        &.link {
          width: auto;
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    form {
      gap: 8px;

      .form-row {
        flex-direction: column;
        gap: 8px;
        .input-row {
          width: 100%;
        }
      }
    }
  }
`;
