import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { showFabricInfoBox } from "../../store/UI";
import { AppDispatch } from "../../store";
import { ButtonEnum, Color, SwatchEnum } from "../../beans";
import { info as infoIcon } from "../../constants/icons";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";

type Props = {
  children?: React.ReactNode;
  size?: SwatchEnum;
  image: string;
  color?: Color;
  title?: string;
  description?: string;
  selected?: boolean;
  info?: boolean;
  code?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
const Swatch = (props: Props) => {
  const { image, info, color, size, description, title, selected, onClick } =
    props;
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Wrap
        className={size ? size : "large"}
        $fabric={image}
        $selected={!!selected}
        $color={color}
      >
        <div className="swatch" onClick={onClick}>
          <div className="fabric-image"></div>
        </div>
        {title && <div className="fabric-description label-lg">{title}</div>}
        {info && (
          <div className="fabric-info-button">
            <Button
              type={ButtonEnum.icon}
              onClick={() =>
                dispatch(
                  showFabricInfoBox({
                    active: {
                      title: "Fabric details",
                      content: description,
                      image: image,
                    },
                  })
                )
              }
            >
              <Icon info icon={infoIcon}></Icon>
            </Button>
          </div>
        )}
      </Wrap>
    </>
  );
};

export default Swatch;
