export enum ButtonEnum {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  icon = "icon",
  link = "link",
}

export enum AlertEnum {
  error = "error",
  info = "info",
  success = "success",
}

export enum PrintPlacement {
  front_center = 1,
  full_back = 2,
  left_center = 3,
}

export enum ActionCardEnum {
  simple = "simple",
  expanded = "expanded",
}

// TODO define all status
export enum OrderStatusEnum {
  created = "created",
  payment_made = "payment made",
}
// TODO define all status
export enum ProductionStatusEnum {
  ordered = "ordered",
}

export enum PaymentStatusEnum {
  created = "created",
  succeeded = "succeeded",
  payment_failed = "payment_failed",
  amount_capturable_updated = "amount_capturable_updated",
  canceled = "canceled",
  requires_ = "requires_",
  partially = "partially",
}

export enum PrintTypeEnum {
  embroidery = "embroidery",
  dtf = "dtf",
  screen_print = "screen_print",
}

export enum PrintLocationEnum {
  front_center = "front_center",
  front_left = "front_left",
  full_back = "full_back",
}

export enum SwatchEnum {
  medium = "medium",
  large = "large",
}

export enum TabEnum {
  fabric = "fabric",
  print = "print",
  label = "label",
  summary = "summary",
}

export enum ModalEnum {
  registration = "registration",
  login = "login",
  error = "error",
}

export type ModalActionButton = {
  type: ButtonEnum;
  onClick: any;
  label: string;
};

export type ArtworkDetail = {
  id: number;
  original_resized: string | undefined;
  quality?: string;
  type: PrintType | null;
  location: string | undefined;
  size?: string;
  number_of_colors?: string[];
  position_x: number;
  position_y: number;
  scale: number;
  width_cm: number;
  height_cm: number;
};

export type Billing = {
  billing_id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  vat_number: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  province: string;
  city: string;
  country: string;
};

export type CageSize = {
  id: number;
  width: string;
  height: string;
  conversion_unit: number;
};

export type Country = {
  iso2: string;
  country: string;
};

export type Color = {
  name: string;
  hex: string;
};

export type ColouredFabric = {
  id: number;
  color: string;
  url: string;
};

export type Design = UserDesign[];

export type Fabrics = {
  name: string;
  code: string;
  composition: string;
  weight_gsm: number;
  description: string;
  fabric_image_url: string;
  coloredfabric_set: Fabric[];
};

export type Fabric = {
  id: number;
  color: Color;
  url: string;
};

export type InfoBox = {
  key: string;
  title: string;
  content: HTMLAllCollection;
  image: string;
};

export type Item = {
  id: number;
  name: string;
  code: string;
  description?: string;
  default_render?: string;
};

export type ItemRender = {
  id: number;
  view: string; //( TODO: enum view types)
  image: string;
  default: boolean;
  item_fabric: number;
};

export type Label = {
  id: number;
  session_key: string;
  name: string;
  image: string;
  user: any; // TODO update user type
  pricing: number;
};

export type Login = {
  key: string;
};

export type OrderList = {
  id: number;
  status: OrderStatusEnum;
  order_placed_date: string;
  estimated_delivery_date: string;
  orderitems: any[];
  production_status: PaymentStatusEnum;
  total_amount: string;
};

// TODO fix types
export type OrderSumamry = {
  products: OrderItem[];
  shipping: string;
  subtotal: string;
};

export type OrderItem = {
  name: string;
  number_units: number;
  item: {
    name: string;
    number_of_units: number;
    cost: number;
  };
  prints: ArtworkDetail[];
  item_total: number;
};

export type PrintType = {
  id: string;
  type: string;
  label: string;
  description: string;
};

export type PrintLocationPlacement =
  | "front_center"
  | "front_left"
  | "full_back";

export type PrintLocation = {
  id: string;
  placement: PrintLocationPlacement;
  print_area_width: number;
  print_area_height: number;
  print_area_top: number;
  print_area_left: number;
  real_height_cm: number;
  real_width_cm: number;
  types_available: string[];
};

export type ProductionDetail = {
  units: Units[];
  productionTime: string;
};

export type Production = {
  id: number;
  design: number;
  sizes: ProductionSize;
  sample: boolean;
};

export type ProductionSize = {
  x_small?: number;
  small?: number;
  medium?: number;
  large?: number;
  x_large?: number;
};

export type ProductionRange = {
  item_fabric_id: number;
  item_lowest_cost: string;
  production_range: string;
  maximum: number;
  minimum: number;
  total: number;
  unit_total: number;
};

export type ProductionRangeAvailability = {
  id: number;
  min: number;
  max: number;
  x_small: boolean;
  small: boolean;
  medium: boolean;
  large: boolean;
  x_large: boolean;
};

export type StripePaymentType =
  | "payment"
  | "setup"
  | "subscription"
  | undefined;

export type Shipping = {
  shipping_id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  vat_number: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  province: string;
  city: string;
  country: string;
  fabbric_warehouse?: true;
  tracking_number?: string;
  status?: string;
  same_as_billing: boolean;
};

export type Units = {
  from: number;
  cost: number;
};

export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
};

export type UserDesignProductionPayment = {
  payment: number;
  status: PaymentStatusEnum;
};

export type UserDesignProduction = {
  order: number;
  order_created: boolean;
  orderitem: number;
  production_id: number;
  payments: UserDesignProductionPayment[];
};

export type UserDesign = {
  id: number;
  user: number;
  session_key: string;
  name: string;
  item_fabric: {
    id: string;
    item: Item;
    col_fabric: Fabric;
  };
  item_render_front: ItemRender;
  item_render_back: ItemRender;
  print_set: ArtworkDetail[];
  production_order_details: {
    productions: UserDesignProduction[];
    production_without_order: number;
  };
};

export type Tab = {
  id: TabEnum;
  label: string;
  url: string;
  selected?: boolean;
};

export type Icon = {
  path: string;
  width?: string;
  height?: string;
  viewBox: string;
};

//// FORM TYPES

export type billingForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  vat_number: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  province: string;
  city: string;
  country: string;
};

export type shippipngForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  vat_number: string;
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  province: string;
  city: string;
  country: string;
  same_as_billing: boolean;
};
