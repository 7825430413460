import { ButtonEnum } from "../../beans";
import { Wrap } from "./styles";
import Button from "../Button";
import ProductionDesigns from "../ProductionDesigns";
import { dateFormat } from "../../utils/format";
import { useNavigate } from "react-router";

type Props = {
  order: any;
};
const OrderDetailsCard = (props: Props) => {
  const { order } = props;
  const navigate = useNavigate();

  return (
    <Wrap>
      <div className="order-card">
        <div className="order-header">
          <div className="order-details">
            <div className="order-placed">
              <div className="label-sm">Order placed</div>
              <div className="title-sm">
                {dateFormat(order.order_placed_date)}
              </div>
            </div>
            <div className="order-total">
              <div className="label-sm">Total</div>
              <div className="title-sm">{order?.total_amount} €</div>
            </div>
          </div>
          <div className="order-actions">
            <div className="label-sm">Order number: {order.id}</div>
            <Button
              type={ButtonEnum.link}
              onClick={() => navigate(`/order/${order.id}/completed/`)}
            >
              View order summary
            </Button>
          </div>
        </div>
        <div className="order-production-details">
          {order.orderitems.map((item: any, k: number) => {
            return (
              <>
                <ProductionDesigns
                  userDesign={item.design}
                  production={item.production}
                  key={`production_${item.design.id}_${k}`}
                />
              </>
            );
          })}
          <div className="order-production-actions">
            <div>
              <div className="title-lg">
                Estimated delivery on{" "}
                {dateFormat(order.estimated_delivery_date)}
              </div>
              <div className="label-lg">
                Production {order.production_status}
              </div>
            </div>
            {/* <Button type={ButtonEnum.primary}>Order again</Button> */}
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default OrderDetailsCard;
