import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  .list-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    div {
      word-wrap: wrap;
    }
  }
`;
