import { Wrap } from "./styles";
import { ProductionRange as ProductionRangeBean } from "../../beans";
import ProductionRangeItem from "../ProductionRangeItem";

type Props = {
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  productionTime?: string;
  productionRange: ProductionRangeBean[];
};

const ProductionRange = (props: Props) => {
  const { productionTime, productionRange, desktopOnly, mobileOnly } = props;

  return (
    <Wrap $mobileOnly={mobileOnly} $desktopOnly={desktopOnly}>
      {productionRange
        ? productionRange
            .slice(1)
            .map((unit, k) => (
              <ProductionRangeItem productionRangeItem={unit} key={k} />
            ))
        : null}
      {productionTime ? (
        <div className="production-detail">
          <div className="production-detail-unit">
            <div className="label-sm">Production time</div>
            <div className="title-sm">{productionTime}</div>
          </div>
        </div>
      ) : null}
    </Wrap>
  );
};

export default ProductionRange;
